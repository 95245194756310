import phoneVerification from '../../lang/phoneVerification';
import { StepHeading } from '../../StepHeading';

export function PhoneNumberVerificationHeading() {
    return (
        <StepHeading
            headerText={phoneVerification.PHONE_NUMBER_VERIFICATION_HEADER}
            contentText={phoneVerification.PHONE_NUMBER_VERIFICATION_CONTENT}
            headerTestId="phoneNumberVerificationHeading"
            contentTestId="phoneNumberVerificationContent"
        />
    );
}
