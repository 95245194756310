import { Button, Checkbox, FormControlLabel, Typography, Link as MuiLink } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { HomePageHeading } from '../../components/HomePage/HomePageHeading';
import lang from '../../components/lang/termsAndConditions';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { VERIFICATION_ROUTE } from '../../constants';
import { TermsAndConditionsLabel } from './TermsAndConditionsLabel';

export function TermsAndConditions() {
    const [isChecked, setIsChecked] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
    };

    const navigate = useNavigate();

    const navigateToVerificationPage = () => {
        navigate(VERIFICATION_ROUTE);
    };

    return (
        <PageWrapper>
            <HomePageHeading />

            <FormControlLabel
                control={<Checkbox checked={isChecked} onChange={handleChange} />}
                label={<TermsAndConditionsLabel />}
                sx={{ mt: 3 }}
            />

            <Typography variant="h4" sx={{ mt: 3 }}>
                {lang.PDF_FIRST_PART_TEXT}{' '}
                <MuiLink href="/dummy.pdf" download="data-and-privacy-policy">
                    {lang.PDF_LINK_TEXT}
                </MuiLink>{' '}
                {lang.PDF_SECOND_PART_TEXT}
            </Typography>

            <Button
                onClick={navigateToVerificationPage}
                variant="contained"
                sx={{ mt: 4, p: '14px 80px' }}
                disabled={!isChecked}
                data-testid="termsAndConditionsButton"
            >
                {lang.START_ONBOARDING}
            </Button>
        </PageWrapper>
    );
}
