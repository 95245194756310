import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import {
    FAILED_REGISTRATION_ROUTE,
    LEGAL_NOTICE_ROUTE,
    VERIFICATION_ROUTE,
    VERIFICATION_STATUS_CHECKER_ROUTE,
    FAILURE_ROUTE,
    SUCCESS_ROUTE,
    WRONG_URL_ERROR_ROUTE,
    WEB_ID_VERIFICATION_ROUTE,
} from '../constants';
import { WebIdVerification } from '../views/WebIdVerification/WebIdVerification';
import { RedirectWhenNoCriticalInfoProvided } from './customRoutes';
import { CheckIfNewProcessStarted } from './customRoutes/CheckIfNewProcessStarted';
import { NotFoundPage, WrongUrlPage } from './Errors';
import { RegistrationFailedPage } from './Errors/RegistrationFailedPage/RegistrationFailedPage';
import { FailurePage } from './FailurePage/FailurePage';
import { Home } from './Home/Home';
import { LegalNotice } from './LegalNotice';
import { Root } from './Root';
import { SuccessPage } from './SuccessPage/SuccessPage';
import { Verification } from './Verification/Verification';
import { VerificationStatusChecker } from './VerificationStatusChecker/VerificationStatusChecker';

const router = createBrowserRouter([
    {
        children: [
            {
                element: (
                    <CheckIfNewProcessStarted>
                        <Home />
                    </CheckIfNewProcessStarted>
                ),
                path: '/',
            },
            {
                element: (
                    <RedirectWhenNoCriticalInfoProvided>
                        <Verification />
                    </RedirectWhenNoCriticalInfoProvided>
                ),
                path: VERIFICATION_ROUTE,
            },
            {
                element: <LegalNotice />,
                path: LEGAL_NOTICE_ROUTE,
            },
        ],
        element: <Root />,
    },
    {
        element: <SuccessPage />,
        path: SUCCESS_ROUTE,
    },
    {
        element: <FailurePage />,
        path: FAILURE_ROUTE,
    },
    {
        element: <VerificationStatusChecker />,
        path: VERIFICATION_STATUS_CHECKER_ROUTE,
    },
    {
        element: (
            <RedirectWhenNoCriticalInfoProvided>
                <WebIdVerification />
            </RedirectWhenNoCriticalInfoProvided>
        ),
        path: WEB_ID_VERIFICATION_ROUTE,
    },
    {
        element: <WrongUrlPage />,
        path: WRONG_URL_ERROR_ROUTE,
    },
    {
        element: <RegistrationFailedPage />,
        path: FAILED_REGISTRATION_ROUTE,
    },
    {
        element: <NotFoundPage />,
        path: '*',
    },
]);

export function Routes() {
    return <RouterProvider router={router} />;
}
