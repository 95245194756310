import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLocalStorage } from '@dock/react-hooks';

import { HomeLoader } from '../../components';
import { WebIdVerificationWithoutAutoRedirect } from '../../components/WebIdVerification';
import { WebIdVerificationWithAutoRedirect } from '../../components/WebIdVerification/WebIdVerificationWithAutoRedirect';
import { LocalStorageKeys, VERIFICATION_STATUS_CHECKER_ROUTE } from '../../constants';
import { useGlobalContext } from '../../hooks';
import { useActiveWebId, useIdentityInfo } from '../../services';

export function WebIdVerification() {
    const { identityVerificationId } = useGlobalContext();

    const { value: identityId } = useLocalStorage<string>(LocalStorageKeys.IDENTITY_ID);

    const { value: productId } = useLocalStorage<string>(LocalStorageKeys.PRODUCT_ID);

    const { value: partnerId } = useLocalStorage<string>(LocalStorageKeys.PARTNER_ID);

    const { data: identityInfoData, isFetching: isIdentityInfoLoading } = useIdentityInfo(
        typeof partnerId === 'string' ? partnerId : '',
        typeof productId === 'string' ? productId : ''
    );

    const shouldRedirectToWebIdDirectly = identityInfoData?.config.isWebidAutoRedirect === true;

    const {
        data,
        error,
        isFetching: isActiveWebIdLoading,
    } = useActiveWebId(
        typeof identityId === 'string' ? identityId : '',
        identityVerificationId || ''
    );

    const isLoading = isIdentityInfoLoading || isActiveWebIdLoading;
    const redirectUrl = data?.redirectUrl ? data.redirectUrl : '';
    const isRedirectButtonDisabled = !redirectUrl || isLoading;

    const navigate = useNavigate();

    const onRedirectToWebIdCallback = () => {
        navigate(VERIFICATION_STATUS_CHECKER_ROUTE);
    };

    // Disabling browser back button
    useEffect(() => {
        const disableBack = () => {
            window.history.forward();
        };

        setTimeout(disableBack, 0);

        window.onunload = () => null;

        return () => {
            window.onunload = null;
        };
    }, []);

    const renderProperElement = useCallback(() => {
        if (isLoading) {
            return <HomeLoader />;
        }

        if (shouldRedirectToWebIdDirectly) {
            return (
                <WebIdVerificationWithAutoRedirect
                    redirectUrl={redirectUrl}
                    onRedirectToWebIdCallback={onRedirectToWebIdCallback}
                />
            );
        }

        return (
            <WebIdVerificationWithoutAutoRedirect
                isRedirectButtonDisabled={isRedirectButtonDisabled}
                onRedirectToWebIdCallback={onRedirectToWebIdCallback}
                redirectUrl={redirectUrl}
                error={error}
            />
        );
    }, [isLoading, shouldRedirectToWebIdDirectly, error]);

    return renderProperElement();
}
