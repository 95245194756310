import { useEffect } from 'react';

import { Alert, useHandleToast } from '@dock/react';
import { ErrorType, expectedErrors } from '@dock/services';

import { DockIdServiceError } from '../../utils/DockIdServiceError';
import lang from '../lang/alert';

type ErrorAlertWrapperProps = {
    error: DockIdServiceError | null;
};

type ExpectedErrorsKey = keyof typeof expectedErrors;

export function ErrorAlertWrapper({ error }: ErrorAlertWrapperProps) {
    const { handleCloseToast, handleOpenToast, isOpen } = useHandleToast();

    const shouldShowMessage = !!error;

    useEffect(() => {
        if (shouldShowMessage) {
            handleOpenToast();
        }
    }, [error]);

    if (!shouldShowMessage) {
        return null;
    }

    const formattedError =
        typeof error.errorCode === 'string' &&
        !!expectedErrors[error.errorCode as ExpectedErrorsKey]
            ? expectedErrors[error.errorCode as ExpectedErrorsKey]
            : ({ title: lang.somethingWentWrong } as ErrorType);

    return (
        <Alert
            severity="error"
            isOpen={isOpen}
            title={formattedError?.title || ''}
            description={formattedError?.message || ''}
            handleCloseToast={handleCloseToast}
        />
    );
}
