import lang from '../../lang/personalDetails';
import { StepHeading } from '../../StepHeading';

export function PersonalDetailsHeading() {
    return (
        <StepHeading
            contentText={lang.CONTENT_TEXT}
            headerText={lang.HEADER_TEXT}
            contentTestId="personalDataContent"
            headerTestId="personalDataHeader"
        />
    );
}
