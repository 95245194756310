import { GenericServiceError } from '@dock/common';
import { WebIdVerificationBody } from '@dock/services';

import { postVerifyWebId } from '../../client';

export const verifyWebId = async (payload: WebIdVerificationBody) => {
    const errorOrResponse = await postVerifyWebId({ payload });

    if (errorOrResponse instanceof GenericServiceError) {
        throw errorOrResponse;
    }

    return errorOrResponse;
};
