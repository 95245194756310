import { flow } from 'fp-ts/lib/function';

import { handleErrors } from '../../../../errorService';
import { requestFlow, getData, RequestAndUnwrap, unwrapService } from '../../../../utilities';

export const emailVerificationPostHandler = requestFlow(handleErrors)(
    `/v0/verification/email-verification`
);

const fetchAndMap = emailVerificationPostHandler<unknown>(getData);

export const verifyEmail: RequestAndUnwrap<'post', unknown> = flow(fetchAndMap, unwrapService);
