import React from 'react';
import { Navigate } from 'react-router-dom';

import { useLocalStorage } from '@dock/react-hooks';

import { LocalStorageKeys } from '../../constants';

type RedirectWhenNoCriticalInfoProvidedType = {
    children: React.ReactNode;
};

export function RedirectWhenNoCriticalInfoProvided({
    children,
}: RedirectWhenNoCriticalInfoProvidedType) {
    const { value: partnerId } = useLocalStorage<string>(LocalStorageKeys.PARTNER_ID);
    const { value: verificationRequestToken } = useLocalStorage<string>(
        LocalStorageKeys.VERIFICATION_REQUEST_TOKEN
    );

    const isCriticalInfoProvided = partnerId && verificationRequestToken;

    return isCriticalInfoProvided ? children : <Navigate to="/" replace />;
}
