import { createRoot } from 'react-dom/client';

import { onDomReady } from './dom_ready';

export function renderToDom(element: JSX.Element, containerId = 'root') {
    onDomReady(() => {
        const container = document.getElementById(containerId);

        if (!container) {
            throw new Error(`Failed to find container dom element with id #${containerId}`);
        }

        const root = createRoot(container);

        root.render(element);
    });
}
