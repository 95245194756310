import { flow } from 'fp-ts/lib/function';

import type { WebIdFieldsResponse } from './webIdFieldsModel';

import { handleErrors } from '../../../../errorService';
import { getData, RequestAndUnwrap, requestFlow, unwrapService } from '../../../../utilities';

export const fetchWebIdFields = (identityId: string, identityVerificationId: string) =>
    requestFlow(handleErrors)(
        `/v0/verification/web-id/fields/${identityId}/${identityVerificationId}`
    );

const fetchAndMap = (identityId: string, identityVerificationId: string) =>
    fetchWebIdFields(identityId, identityVerificationId)<WebIdFieldsResponse>(getData);

export const getWebIdFields = (
    identityId: string,
    identityVerificationId: string
): RequestAndUnwrap<'get', WebIdFieldsResponse> =>
    flow(fetchAndMap(identityId, identityVerificationId), unwrapService<WebIdFieldsResponse>);
