import { useQuery } from '@tanstack/react-query';

import type { WebIdFieldsResponse } from '@dock/services';

import { DockIdServiceError } from '../../../utils/DockIdServiceError';
import { WEB_ID_FIELDS_KEY } from '../../keys';
import { fetchWebIdFields } from '../methods';

export function useWebIdFields(identityId: string, identityVerificationId: string) {
    const { data, error, isError, isLoading } = useQuery<
        WebIdFieldsResponse,
        DockIdServiceError,
        WebIdFieldsResponse
    >(
        [WEB_ID_FIELDS_KEY, identityId, identityVerificationId],
        () => fetchWebIdFields(identityId, identityVerificationId),
        {
            enabled: !!identityId && !!identityVerificationId,
        }
    );

    return { data, error, isError, isLoading };
}
