import { Typography, Button, Box } from '@mui/material';
import { useEffect } from 'react';

import lang from '../../lang/redirectToWebId';

type RedirectToWebIdContentTypes = {
    redirectUrl: string;
    onRedirectToWebIdCallback: () => void;
};

export const wrapperStyles = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
};

export function WebIdVerificationWithAutoRedirect({
    onRedirectToWebIdCallback,
    redirectUrl,
}: RedirectToWebIdContentTypes) {
    useEffect(() => {
        if (redirectUrl) {
            const newTab = window.open(redirectUrl, '_blank');

            if (newTab) {
                onRedirectToWebIdCallback();
            }
        }
    }, [redirectUrl]);

    return (
        <Box sx={wrapperStyles}>
            <Typography
                component="h1"
                variant="h1"
                data-testid="webIdVerificationWithAutoRedirectHeader"
            >
                {lang.HEADING}
            </Typography>
            <Typography
                component="h2"
                variant="h2"
                sx={{ color: 'primary.dark' }}
                data-testid="webIdVerificationWithAutoRedirectContent"
            >
                {lang.CONTENT}
            </Typography>

            <Button
                href={redirectUrl}
                target="_blank"
                onClick={onRedirectToWebIdCallback}
                disabled={!redirectUrl}
                variant="contained"
                sx={{ mt: 4, px: 10, py: 1.75 }}
                data-testid="webIdVerificationButton"
            >
                {lang.BUTTON_TEXT}
            </Button>
        </Box>
    );
}
