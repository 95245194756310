import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Grid } from '@mui/material';
import { useIsFetching } from '@tanstack/react-query';
import { Link as RouterLink } from 'react-router-dom';

import { LEGAL_NOTICE_ROUTE } from '../../constants';
import { useGlobalContext } from '../../hooks';
import { FooterLogoIcon } from '../icons';
import lang from '../lang/footer';

export function Footer() {
    const { onBackButtonHandler } = useGlobalContext();

    const isBackButtonAvailable = onBackButtonHandler !== null;

    const isFetching = !!useIsFetching();

    if (isFetching) {
        return null;
    }

    return (
        <Box
            sx={{
                bottom: '52px',
                height: '100px',
                left: 0,
                position: 'absolute',
                width: '100%',
            }}
        >
            <Grid
                container
                spacing="16px"
                sx={{ alignItems: 'center', justifyContent: 'space-between' }}
            >
                <Grid item xs={4} sx={{ textAlign: 'center' }}>
                    {isBackButtonAvailable && (
                        <Button
                            startIcon={<ArrowBackIcon />}
                            sx={{ fontSize: '14px' }}
                            onClick={onBackButtonHandler}
                        >
                            {lang.BACK}
                        </Button>
                    )}
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'center' }}>
                    <FooterLogoIcon />
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={{
                        color: 'text.main',
                        fontSize: '16px',
                        textAlign: 'center',
                    }}
                >
                    <RouterLink
                        to={LEGAL_NOTICE_ROUTE}
                        style={{ color: 'inherit' }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {lang.LEGAL_NOTICE}
                    </RouterLink>
                </Grid>
            </Grid>
        </Box>
    );
}
