import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { SUCCESS_ROUTE } from '../constants';
import { useGlobalContext } from './useGlobalContext';

export const useRedirectToSuccessPage = (conditionToRedirect: boolean, dependencies: unknown[]) => {
    const { setOnBackButtonHandler } = useGlobalContext();

    const navigate = useNavigate();

    useEffect(() => {
        if (conditionToRedirect) {
            setOnBackButtonHandler(null);
            navigate(SUCCESS_ROUTE);
        }
    }, dependencies);
};
