import { useEffect } from 'react';

import { useLocalStorage } from '@dock/react-hooks';

import { LocalStorageKeys } from '../constants';
import { getCurrentStep } from '../routes/Home/utils';
import { useIdentityStatus } from '../services';
import { useActiveSteps } from './useActiveSteps';
import { useGlobalContext } from './useGlobalContext';

export const useSteps = () => {
    const { setActiveSteps, setCurrentStep, setShouldShowNavbar } = useGlobalContext();

    const { value: identityId } = useLocalStorage<string>(LocalStorageKeys.IDENTITY_ID);

    const isIdentityIdProvided = typeof identityId === 'string';

    const {
        data: identityStatusData,
        error: identityStatusError,
        isError: identityStatusIsError,
        isLoading: identityStatusIsLoading,
    } = useIdentityStatus(isIdentityIdProvided ? identityId : '');

    const activeSteps = useActiveSteps(identityStatusData);

    useEffect(() => {
        if (identityStatusData) {
            const step = getCurrentStep(
                identityStatusData?.possibleStepperSteps,
                identityStatusData?.currentStep
            );

            setCurrentStep(step);
        }
    }, [identityStatusData]);

    useEffect(() => {
        if (activeSteps) {
            setActiveSteps(activeSteps);
        }
    }, [activeSteps]);

    useEffect(() => {
        setShouldShowNavbar(!identityStatusIsLoading);
    }, [identityStatusIsLoading]);

    return {
        identityStatusData,
        identityStatusError,
        identityStatusIsError,
        identityStatusIsLoading,
    };
};
