import type { Control } from 'react-hook-form';

import { OTP_CODE_LENGTH_6 } from '@dock/common';
import { useLocalStorage } from '@dock/react-hooks';

import type { EmailConfirmationFormValues } from '../../../views/EmailConfirmation/EmailConfirmation';

import { LocalStorageKeys } from '../../../constants';
import { useEmailContext, useGlobalContext } from '../../../hooks';
import { useVerifyEmail } from '../../../services';
import { ConfirmationStepForm } from '../../ConfirmationStepForm/ConfirmationStepForm';
import { ErrorAlertWrapper } from '../../ErrorAlertWrapper/ErrorAlertWrapper';
import lang from '../../lang/emailConfirmation';

type EmailConfirmationFormContentType = {
    control: Control<EmailConfirmationFormValues, unknown>;
    isSubmitButtonDisabled: boolean;
    isLoading: boolean;
};

export function EmailConfirmationFormContent({
    control,
    isLoading,
    isSubmitButtonDisabled,
}: EmailConfirmationFormContentType) {
    const { error, verifyEmailMutation } = useVerifyEmail();
    const { identityVerificationId } = useGlobalContext();
    const { email } = useEmailContext();

    const { value: identityId } = useLocalStorage<string>(LocalStorageKeys.IDENTITY_ID);

    const onResendEmailHandler = async () => {
        if (!email) {
            return;
        }

        await verifyEmailMutation({
            emailAddress: email || '',
            identityId: typeof identityId === 'string' ? identityId : '',
            identityVerificationId: identityVerificationId || '',
        });
    };

    return (
        <>
            <ConfirmationStepForm<EmailConfirmationFormValues>
                confirmationButtonText={lang.CONFIRM_EMAIL_BUTTON}
                control={control}
                resendCallback={onResendEmailHandler}
                isSubmitButtonDisabled={isSubmitButtonDisabled}
                isLoading={isLoading}
                maxLength={OTP_CODE_LENGTH_6}
            />

            <ErrorAlertWrapper error={error} />
        </>
    );
}
