import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLocalStorage } from '@dock/react-hooks';

import { PageLoaderWithCheckingStatusFunctionality } from '../../components/PageLoaderWithCheckingStatusFunctionality';
import {
    FAILED_REGISTRATION_ROUTE,
    FAILURE_ROUTE,
    LocalStorageKeys,
    SUCCESS_ROUTE,
} from '../../constants';
import { useGlobalContext } from '../../hooks';
import { useActiveWebId, useIdentityStatus } from '../../services';
import { isEveryStatusCompleted, isAnyStatusStatusFailedOrCanceled } from './utils';

export function VerificationStatusChecker() {
    const { identityVerificationId } = useGlobalContext();

    const { value: identityId } = useLocalStorage<string>(LocalStorageKeys.IDENTITY_ID);

    const isIdentityIdProvided = typeof identityId === 'string';

    const { data: identityStatusData, isError: identityStatusIsError } = useIdentityStatus(
        isIdentityIdProvided ? identityId : '',
        true
    );

    const navigate = useNavigate();

    useEffect(() => {
        if (identityStatusIsError) {
            navigate(FAILED_REGISTRATION_ROUTE, { replace: true });
        }
    }, [identityStatusIsError]);

    // Get redirect URL
    const { data } = useActiveWebId(
        typeof identityId === 'string' ? identityId : '',
        identityVerificationId || ''
    );
    const redirectUrl = data?.redirectUrl ? data.redirectUrl : '';

    useEffect(() => {
        if (identityStatusData?.verificationStatuses) {
            const { verificationStatuses } = identityStatusData;

            if (isEveryStatusCompleted(verificationStatuses)) {
                navigate(SUCCESS_ROUTE);
            }

            if (isAnyStatusStatusFailedOrCanceled(verificationStatuses)) {
                navigate(FAILURE_ROUTE);
            }
        }
    }, [identityStatusData?.verificationStatuses]);

    return <PageLoaderWithCheckingStatusFunctionality redirectUrl={redirectUrl} />;
}
