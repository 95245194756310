import lang from '../../components/lang/webIdStatus';
import { Status, StatusPage } from '../../components/StatusPage/StatusPage';

export function SuccessPage() {
    return (
        <StatusPage
            additionalInfoContent={lang.additionalSuccessInfoContent}
            headingContent={lang.headingSuccessContent}
            status={Status.SUCCESS}
        />
    );
}
