export enum HomePageSearchParams {
    PARTNER_ID = 'partner-id',
    VERIFICATION_REQUEST_TOKEN = 'verification-request-token',
    PRODUCT_ID = 'product-id',
}

export enum LocalStorageKeys {
    PARTNER_ID = 'partnerId',
    VERIFICATION_REQUEST_TOKEN = 'verificationRequestToken',
    PRODUCT_ID = 'productId',
    IDENTITY_ID = 'identityId',
    LOGO_URL = 'logoUrl',
}
