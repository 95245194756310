import Typography from '@mui/material/Typography';

import { ErrorsWrapper } from '@dock/react';

import lang from '../lang/errors';

export function WrongUrlPage() {
    return (
        <ErrorsWrapper shouldDisplayDockLogo={false}>
            <Typography
                component="h1"
                variant="inherit"
                sx={{
                    color: 'primary.main',
                    fontSize: '80px',
                    fontWeight: 'bold',
                    lineHeight: '109.28px',
                }}
            >
                {lang.ERROR_OCCURRED}
            </Typography>

            <Typography variant="h3" component="h3" sx={{ maxWidth: '525px', mb: 2 }}>
                {lang.WRONG_URL_CONTENT}
            </Typography>
        </ErrorsWrapper>
    );
}
