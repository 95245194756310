import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { Footer } from '../components/Footer/Footer';
import { Navbar } from '../components/Navbar';

const FOOTER_FULL_HEIGHT = '152px';

export function Root() {
    return (
        <Box sx={{ minHeight: '100vh', position: 'relative' }}>
            <Navbar />
            <Box sx={{ pb: FOOTER_FULL_HEIGHT }}>
                <Outlet />
            </Box>
            <Footer />
        </Box>
    );
}
