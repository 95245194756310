export enum VerifyStatus {
    EMAIL = 'E-mail',
    PHONE = 'Phone',
    WEB_ID = 'WebId',
    DOCK_ID = 'DockID',
    NONE = 'None',
}

export enum VerificationStatusPart {
    NEW = 'new',
    PENDING = 'pending',
    COMPLETED = 'completed',
    FAILED = 'failed',
    CANCELED = 'canceled',
}

export type VerificationStatusesType = {
    id: string;
    status: VerificationStatusPart;
    verifier: VerifyStatus;
};

export type IdentityStatusResponse = {
    brandDetails: {
        logoUrl: string;
        primaryColor: string;
        secondaryColor: string;
    };
    identityId: string;
    verificationStatuses: VerificationStatusesType[];
};
