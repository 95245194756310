import { Box, CircularProgress, Typography } from '@mui/material';

import lang from '../lang/homeLoader';

type HomeLoaderTypes = { loadingText?: string };

export function HomeLoader({ loadingText = lang.LOADING_TEXT }: HomeLoaderTypes) {
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                height: '100%',
                justifyContent: 'center',
                position: 'absolute',
                top: 0,
                width: '100%',
            }}
        >
            <CircularProgress size={80} />
            <Typography component="h1" variant="h4">
                {loadingText}
            </Typography>
        </Box>
    );
}
