import lang from '../../lang/emailConfirmation';
import { StepHeading } from '../../StepHeading';

type EmailConfirmationHeadingTypes = {
    providedEmail: string | undefined;
};

export function EmailConfirmationHeading({ providedEmail }: EmailConfirmationHeadingTypes) {
    return (
        <StepHeading
            headerText={lang.CONFIRM_YOUR_EMAIL_HEADING}
            contentText={`${lang.CONFIRM_YOUR_EMAIL_CONTENT} ${providedEmail || '-'}`}
            headerTestId="emailConfirmationHeading"
            contentTestId="emailConfirmationContent"
        />
    );
}
