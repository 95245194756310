import { IdentityStatusResponse, VerificationStatusPart, VerifyStatus } from '@dock/services';

const NOT_FOUND_INDEX = -1;

type IdentityVerificationBaseType = {
    id: string;
    verifier: VerifyStatus;
};

type IdentityVerificationCurrentStepType = IdentityVerificationBaseType & {
    status: VerificationStatusPart;
};

export type IdentityStatusDataReturnType = IdentityStatusResponse & {
    currentStep: IdentityVerificationCurrentStepType | undefined;
    possibleStepperSteps: VerifyStatus[];
    isRegistrationFailedOrCanceled: boolean;
    identityVerificationsIds: IdentityVerificationBaseType[];
    shouldRedirectToErrorPage: boolean;
    shouldRedirectToSuccessPage: boolean;
};

export const selectStatusMapper = (
    responseData: IdentityStatusResponse
): IdentityStatusDataReturnType => {
    const { verificationStatuses } = responseData;

    const getIsFailedOrCancelled = (status: VerificationStatusPart): boolean =>
        status === VerificationStatusPart.FAILED || status === VerificationStatusPart.CANCELED;

    const isRegistrationFailedOrCanceled = !!verificationStatuses.find(({ status }) =>
        getIsFailedOrCancelled(status)
    );

    const shouldRedirectToSuccessPage = !verificationStatuses.find(
        ({ status }) =>
            status === VerificationStatusPart.NEW ||
            status === VerificationStatusPart.PENDING ||
            getIsFailedOrCancelled(status)
    );

    const possibleSteps = verificationStatuses
        .map(({ verifier }) => {
            if (verifier === VerifyStatus.WEB_ID) {
                return [VerifyStatus.DOCK_ID, VerifyStatus.WEB_ID];
            }

            return verifier;
        })
        .flat();

    const pendingStatusIndex = verificationStatuses.findIndex(
        ({ status }) => status === VerificationStatusPart.PENDING
    );

    const identityVerificationsIds = verificationStatuses
        .map(({ id, verifier }) => {
            const verificationIds = { id, verifier: verifier as VerifyStatus };

            if (verifier === VerifyStatus.WEB_ID) {
                const dockIdVerifier = { id, verifier: VerifyStatus.DOCK_ID };
                return [dockIdVerifier, verificationIds];
            }

            return verificationIds;
        })
        .flat();

    if (pendingStatusIndex !== NOT_FOUND_INDEX) {
        const currentStep = verificationStatuses[pendingStatusIndex];

        return {
            ...responseData,
            currentStep,
            identityVerificationsIds,
            isRegistrationFailedOrCanceled,
            possibleStepperSteps: possibleSteps,
            shouldRedirectToErrorPage: !verificationStatuses.length,
            shouldRedirectToSuccessPage,
        };
    }

    const newStatusIndex = verificationStatuses.findIndex(
        ({ status }) => status === VerificationStatusPart.NEW
    );
    const lastStatusIndex = verificationStatuses.slice(-1)[0];

    const currentStep =
        newStatusIndex === -1 ? lastStatusIndex : verificationStatuses[newStatusIndex];

    return {
        ...responseData,
        currentStep,
        identityVerificationsIds,
        isRegistrationFailedOrCanceled,
        possibleStepperSteps: possibleSteps,
        shouldRedirectToErrorPage: !verificationStatuses.length,
        shouldRedirectToSuccessPage,
    };
};
