import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { is401Error } from '@dock/services';

import { WRONG_URL_ERROR_ROUTE } from '../constants';
import { FAILED_REFRESH_TOKEN_EVENT } from '../services';

export const useRedirectToWrongUrlPage = (
    conditionToRedirect: boolean,
    dependencies: unknown[],
    error?: unknown
) => {
    const navigate = useNavigate();

    const handleNavigate = () => navigate(WRONG_URL_ERROR_ROUTE);

    useEffect(() => {
        window.addEventListener(FAILED_REFRESH_TOKEN_EVENT, handleNavigate);

        return () => window.removeEventListener(FAILED_REFRESH_TOKEN_EVENT, handleNavigate);
    }, []);

    useEffect(() => {
        if (conditionToRedirect && !is401Error(error)) {
            handleNavigate();
        }
    }, [...dependencies, error]);
};
