import { flow } from 'fp-ts/lib/function';

import type { ActiveWebIdVerificationResponse } from './activeWebIdVerificationModel';

import { handleErrors } from '../../../../errorService';
import { getData, RequestAndUnwrap, requestFlow, unwrapService } from '../../../../utilities';

export const fetchVerifyActiveWebId = (identityId: string, identityVerificationId: string) =>
    requestFlow(handleErrors)(
        `/v0/verification/web-id/active/${identityId}/${identityVerificationId}`
    );

const fetchAndMap = (identityId: string, identityVerificationId: string) =>
    fetchVerifyActiveWebId(
        identityId,
        identityVerificationId
    )<ActiveWebIdVerificationResponse>(getData);

export const getVerifyActiveWebId = (
    identityId: string,
    identityVerificationId: string
): RequestAndUnwrap<'get', ActiveWebIdVerificationResponse> =>
    flow(
        fetchAndMap(identityId, identityVerificationId),
        unwrapService<ActiveWebIdVerificationResponse>
    );
