import { getIdentityStatus } from '../../client';

export const fetchIdentityStatus = async (identityId: string) => {
    const identityStatusOrError = await getIdentityStatus(identityId);

    if (identityStatusOrError instanceof Error) {
        throw identityStatusOrError;
    }
    return identityStatusOrError;
};
