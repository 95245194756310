import * as E from 'fp-ts/lib/Either';

import { ACCESS_TOKEN, InternalRequestConfig, safeParseJson } from '@dock/common';

export const interceptorRequest = async (
    config: InternalRequestConfig
): Promise<InternalRequestConfig> => {
    const newConfig = { ...config };

    if (newConfig.headers && newConfig.url) {
        const accessToken = window.localStorage.getItem(ACCESS_TOKEN);
        const parsedValue = safeParseJson<string>(accessToken || '');

        if (E.isRight(parsedValue)) {
            newConfig.headers['X-Dockid-Authorization'] = `Bearer ${parsedValue.right}`;
        }
    }

    return newConfig;
};
