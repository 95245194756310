import lang from '../lang/navbar';

type LogoType = {
    logoUrl: string;
};

export const LOGO_MAX_WIDTH = '220px';
export const LOGO_MAX_HEIGHT = '100px';

export function Logo({ logoUrl }: LogoType) {
    return (
        <img
            style={{
                maxHeight: LOGO_MAX_HEIGHT,
                maxWidth: LOGO_MAX_WIDTH,
                objectFit: 'contain',
                objectPosition: 'center',
            }}
            src={logoUrl}
            alt={lang.logoAltText}
        />
    );
}
