import { flow } from 'fp-ts/lib/function';

import type { IdentityStatusResponse } from './identityStatusModel';

import { handleErrors } from '../../../../errorService';
import { getData, RequestAndUnwrap, requestFlow, unwrapService } from '../../../../utilities';

export const fetchIdentityStatus = (identityId: string) =>
    requestFlow(handleErrors)(`/v0/natural-person/identity/status/${identityId}`);

const fetchAndMap = (identityId: string) =>
    fetchIdentityStatus(identityId)<IdentityStatusResponse>(getData);

export const getIdentityStatus = (
    identityId: string
): RequestAndUnwrap<'get', IdentityStatusResponse> =>
    flow(fetchAndMap(identityId), unwrapService<IdentityStatusResponse>);
