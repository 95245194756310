export enum WebIdFieldsNames {
    GENDER = 'gender',
    GIVEN_NAMES = 'given_names',
    SURNAMES = 'surnames',
    DATE_OF_BIRTH = 'date_of_birth',
    EMAIL_ADDRESS = 'email_address',
    ZIP = 'zip',
    CITY = 'city',
    COUNTRY = 'country',
}

export enum Gender {
    MALE = 'Male',
    FEMALE = 'Female',
    DIVERSE = 'Diverse',
}
