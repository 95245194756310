import { flow } from 'fp-ts/lib/function';

import { handleErrors } from '../../../../errorService';
import { requestFlow, getData, RequestAndUnwrap, unwrapService } from '../../../../utilities';

export const phoneVerificationPostHandler = requestFlow(handleErrors)(
    `/v0/verification/mobile-number-verification`
);

const fetchAndMap = phoneVerificationPostHandler<unknown>(getData);

export const verifyPhoneNumber: RequestAndUnwrap<'post', unknown> = flow(
    fetchAndMap,
    unwrapService
);
