import { useMutation } from '@tanstack/react-query';

import type { PhoneNumberVerificationBody } from '@dock/services';

import { formatCountryCodeIntoReadableNumber } from '../../../utils';
import { DockIdServiceError } from '../../../utils/DockIdServiceError';
import { verifyPhoneNumber } from '../methods';

export function useVerifyPhoneNumber(handleCallback?: (phoneNumber: string) => void) {
    const { data, error, isError, isLoading, mutateAsync } = useMutation<
        unknown,
        DockIdServiceError,
        PhoneNumberVerificationBody
    >((payload) => verifyPhoneNumber(payload), {
        onSuccess: (_, variables) => {
            if (handleCallback) {
                const formattedNumber = formatCountryCodeIntoReadableNumber(variables.mobileNumber);
                handleCallback(formattedNumber);
            }
        },
    });

    return {
        data,
        error,
        isError,
        isLoading,
        verifyPhoneNumberMutation: mutateAsync,
    };
}
