import { GenericServiceError } from '@dock/common';
import { EmailVerificationBody } from '@dock/services';

import { postVerifyEmail } from '../../client';

export const verifyEmail = async (payload: EmailVerificationBody) => {
    const errorOrResponse = await postVerifyEmail({ payload });

    if (errorOrResponse instanceof GenericServiceError) {
        throw errorOrResponse;
    }

    return errorOrResponse;
};
