import { flow } from 'fp-ts/lib/function';

import { handleErrors } from '../../../../errorService';
import { requestFlow, getData, RequestAndUnwrap, unwrapService } from '../../../../utilities';

export const emailVerificationCallbackPostHandler = requestFlow(handleErrors);

export const postVerifyEmailCallback = () =>
    emailVerificationCallbackPostHandler(`/v0/verification/email-verification/callback`);

const writeAndMap = () => postVerifyEmailCallback()(getData);

export const verifyEmailCallback: RequestAndUnwrap<'post', unknown> = flow(
    writeAndMap(),
    unwrapService
);
