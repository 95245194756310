import { Box, Button, CircularProgress } from '@mui/material';

import type { Control } from 'react-hook-form';

import type { ValidFieldsType } from '../../../views/PersonalDetails/PersonalDetails';

import lang from '../../lang/personalDetails';

export type PersonalDetailsFormType = {
    control: Control;
    validFields: ValidFieldsType[];
    isButtonDisabled: boolean;
    isLoading: boolean;
};

export function PersonalDetailsForm({
    control,
    isButtonDisabled,
    isLoading,
    validFields,
}: PersonalDetailsFormType) {
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                mt: 4.25,
                width: '400px',
            }}
        >
            {validFields.map(({ element }) => element(control))}

            <Button
                variant="contained"
                type="submit"
                disabled={isButtonDisabled}
                sx={{ mb: 6, p: '14px 80px' }}
                endIcon={
                    isLoading && (
                        <CircularProgress data-testid="loadingIcon" color="inherit" size="1.2rem" />
                    )
                }
                data-testid="personalDetailsSubmitButton"
            >
                {lang.SUBMIT_BUTTON}
            </Button>
        </Box>
    );
}
