import { Box, Button, CircularProgress } from '@mui/material';

import type { Control } from 'react-hook-form';

import type { EmailVerificationFormValues } from '../../../views/EmailVerification/EmailVerification';

import { VerifyFieldsEnum } from '../../../views/EmailVerification/constants/enums';
import { ControlledInput } from '../../ControlledInput/ControlledInput';
import lang from '../../lang/emailVerification';

type EmailVerificationFormContentType = {
    control: Control<EmailVerificationFormValues, unknown>;
    isSubmitButtonDisabled: boolean;
    isLoading: boolean;
};

export function EmailVerificationFormContent({
    control,
    isLoading,
    isSubmitButtonDisabled,
}: EmailVerificationFormContentType) {
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                justifyContent: 'center',
                maxWidth: '400px',
                mt: 5,
                width: '100%',
            }}
        >
            <ControlledInput
                control={control}
                label={lang.EMAIL_ADDRESS}
                name={VerifyFieldsEnum.EMAIL_ADDRESS}
                isFullWidth
            />

            <Button
                variant="contained"
                type="submit"
                sx={{ px: 10, py: 1.75 }}
                data-testid="verifyEmail"
                disabled={isSubmitButtonDisabled}
                endIcon={
                    isLoading && (
                        <CircularProgress data-testid="loadingIcon" color="inherit" size="1.2rem" />
                    )
                }
            >
                {lang.VERIFY_EMAIL}
            </Button>
        </Box>
    );
}
