import { useQuery } from '@tanstack/react-query';

import { useLocalStorage } from '@dock/react-hooks';

import { LocalStorageKeys } from '../../../constants';
import { IDENTITY_INFO_KEY } from '../../keys';
import { fetchIdentityInfo } from '../methods';

export function useIdentityInfo(partnerId: string, productId: string) {
    const { setValue: setLogoUrl } = useLocalStorage<string>(LocalStorageKeys.LOGO_URL);

    return useQuery(
        [IDENTITY_INFO_KEY, partnerId, productId],
        () => fetchIdentityInfo(partnerId, productId),
        {
            enabled: !!partnerId && !!productId,
            onSuccess: ({ brandDetails: { logoStorageKey } }) => {
                setLogoUrl(logoStorageKey || '');
            },
        }
    );
}
