import { Box } from '@mui/material';
import React from 'react';

type NavbarWrapperProps = {
    children?: React.ReactNode;
};

export function NavbarWrapper({ children }: NavbarWrapperProps) {
    return (
        <Box
            sx={{
                alignItems: 'center',
                boxShadow: '0px 14px 30px 0px #0C15260F',
                display: 'flex',
                height: '112px',
                overflow: 'hidden',
                width: '100%',
            }}
        >
            {children}
        </Box>
    );
}
