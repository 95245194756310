import { Box, Typography } from '@mui/material';

type StepHeadingType = {
    headerText: string;
    headerTestId: string;
    contentText: string;
    contentTestId: string;
};

export function StepHeading({
    contentTestId,
    contentText,
    headerTestId,
    headerText,
}: StepHeadingType) {
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                justifyContent: 'center',
                textAlign: 'center',
                width: '100%',
            }}
        >
            <Typography
                component="h1"
                variant="inherit"
                sx={{
                    fontSize: '56px',
                    lineHeight: '76.5px',
                    mb: 1,
                }}
                data-testid={headerTestId}
            >
                {headerText}
            </Typography>
            <Typography variant="h4" data-testid={contentTestId}>
                {contentText}
            </Typography>
        </Box>
    );
}
