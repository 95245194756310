import { useMutation } from '@tanstack/react-query';

import type { MobilePhoneVerificationCallbackBody } from '@dock/services';

import { DockIdServiceError } from '../../../utils/DockIdServiceError';
import { verifyPhoneNumberCallback } from '../methods';

export function useVerifyPhoneNumberCallback(handleCallback?: () => void) {
    const { data, error, isError, isLoading, mutateAsync } = useMutation<
        unknown,
        DockIdServiceError,
        MobilePhoneVerificationCallbackBody
    >((payload) => verifyPhoneNumberCallback(payload), {
        onSuccess: () => handleCallback && handleCallback(),
    });

    return {
        data,
        error,
        isError,
        isLoading,
        verifyPhoneNumberCallbackMutation: mutateAsync,
    };
}
