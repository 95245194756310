import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ThemeRoot } from './components/ThemeRoot/ThemeRoot';
import { ENV_VARS } from './constants/envVars';
import { EmailProvider, GlobalProvider, PhoneNumberProvider } from './hooks';
import { Routes } from './routes/Routes';
import { queryClient } from './services';

export function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <GlobalProvider>
                <EmailProvider>
                    <PhoneNumberProvider>
                        <ThemeRoot>
                            <Routes />
                        </ThemeRoot>
                    </PhoneNumberProvider>
                </EmailProvider>
            </GlobalProvider>
            {ENV_VARS.IS_DEVELOPMENT_BUILD && <ReactQueryDevtools />}
        </QueryClientProvider>
    );
}
