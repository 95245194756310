import { Box, Button } from '@mui/material';

import { HomeLoader } from '../HomeLoader';
import lang from '../lang/homeLoader';
import webIdVerificationLang from '../lang/webIdVerification';

type Props = {
    redirectUrl: string;
};

export function PageLoaderWithCheckingStatusFunctionality({ redirectUrl }: Props) {
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <HomeLoader loadingText={lang.CHECKING_FOR_STATUS_TEXT} />
            <Button
                href={redirectUrl}
                target="_blank"
                variant="contained"
                sx={{
                    p: '14px 80px',
                    position: 'absolute',
                    top: '65%',
                }}
                data-testid="webIdVerificationButton"
            >
                {webIdVerificationLang.COMPLETE_VERIFICATION}
            </Button>
        </Box>
    );
}
