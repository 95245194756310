import type { Control } from 'react-hook-form';

import { countriesArray } from '@dock/common';
import { ControlledDesktopDatePicker, ControlledSingleSelect } from '@dock/react';
import { DATE_FORMAT } from '@dock/react-util';

import { ControlledInput } from '../../../components/ControlledInput/ControlledInput';
import lang from '../../../components/lang/personalDetails';
import { WebIdFieldsNames } from './enums';
import { genderOptions } from './genders';

export const possibleFields = [
    {
        element: (control: Control) => (
            <ControlledInput
                key={WebIdFieldsNames.GIVEN_NAMES}
                control={control}
                label={lang.GIVEN_NAME}
                name={WebIdFieldsNames.GIVEN_NAMES}
                isFullWidth
            />
        ),
        fieldDefaultValue: '',
        name: WebIdFieldsNames.GIVEN_NAMES,
    },
    {
        element: (control: Control) => (
            <ControlledInput
                key={WebIdFieldsNames.SURNAMES}
                control={control}
                label={lang.SURNAME}
                name={WebIdFieldsNames.SURNAMES}
                isFullWidth
            />
        ),
        fieldDefaultValue: '',
        name: WebIdFieldsNames.SURNAMES,
    },
    {
        element: (control: Control) => (
            <ControlledSingleSelect
                key={WebIdFieldsNames.GENDER}
                control={control}
                label={lang.GENDER}
                name={WebIdFieldsNames.GENDER}
                options={genderOptions}
            />
        ),
        fieldDefaultValue: '',
        name: WebIdFieldsNames.GENDER,
    },
    {
        element: (control: Control) => (
            <ControlledInput
                key={WebIdFieldsNames.EMAIL_ADDRESS}
                control={control}
                label={lang.EMAIL_ADDRESS}
                name={WebIdFieldsNames.EMAIL_ADDRESS}
                isFullWidth
            />
        ),
        fieldDefaultValue: '',
        name: WebIdFieldsNames.EMAIL_ADDRESS,
    },
    {
        element: (control: Control) => (
            <ControlledDesktopDatePicker
                control={control}
                key={WebIdFieldsNames.DATE_OF_BIRTH}
                name={WebIdFieldsNames.DATE_OF_BIRTH}
                label={lang.DATE_OF_BIRTH}
                inputFormat={DATE_FORMAT}
            />
        ),
        fieldDefaultValue: new Date(),
        name: WebIdFieldsNames.DATE_OF_BIRTH,
    },
    {
        element: (control: Control) => (
            <ControlledInput
                key={WebIdFieldsNames.CITY}
                control={control}
                label={lang.CITY}
                name={WebIdFieldsNames.CITY}
                isFullWidth
            />
        ),
        fieldDefaultValue: '',
        name: WebIdFieldsNames.CITY,
    },
    {
        element: (control: Control) => (
            <ControlledInput
                key={WebIdFieldsNames.ZIP}
                control={control}
                label={lang.ZIP_CODE}
                name={WebIdFieldsNames.ZIP}
                isFullWidth
            />
        ),
        fieldDefaultValue: '',
        name: WebIdFieldsNames.ZIP,
    },
    {
        element: (control: Control) => (
            <ControlledSingleSelect
                key={WebIdFieldsNames.COUNTRY}
                control={control}
                label={lang.COUNTRY}
                name={WebIdFieldsNames.COUNTRY}
                options={countriesArray()}
                defaultOption=""
            />
        ),
        fieldDefaultValue: '',
        name: WebIdFieldsNames.COUNTRY,
    },
];
