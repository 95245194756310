import { flow } from 'fp-ts/lib/function';

import type { WebIdVerificationResponse } from './webIdVerificationModel';

import { handleErrors } from '../../../../errorService';
import { requestFlow, getData, RequestAndUnwrap, unwrapService } from '../../../../utilities';

export const webIdVerificationPostHandler = requestFlow(handleErrors)(
    `/v0/verification/web-id-verification`
);

const fetchAndMap = webIdVerificationPostHandler<WebIdVerificationResponse>(getData);

export const verifyWebId: RequestAndUnwrap<'post', WebIdVerificationResponse> = flow(
    fetchAndMap,
    unwrapService<WebIdVerificationResponse>
);
