import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { Control, FormProvider, useForm } from 'react-hook-form';

import { useLocalStorage } from '@dock/react-hooks';
import { transformDate, DATE_FORMAT } from '@dock/react-util';
import { getPersonalDetailsSchema } from '@dock/validation';

import type { WebIdFieldsNames } from './constants/enums';

import { HomeLoader } from '../../components';
import { ErrorAlertWrapper } from '../../components/ErrorAlertWrapper/ErrorAlertWrapper';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { PersonalDetailsForm, PersonalDetailsHeading } from '../../components/PersonalDetails';
import { LocalStorageKeys } from '../../constants';
import { useGlobalContext } from '../../hooks';
import { useVerifyWebId, useWebIdFields } from '../../services';
import { possibleFields } from './constants/possibleFields';

export type PersonalDataFormValues = {
    [WebIdFieldsNames.GENDER]?: string;
    [WebIdFieldsNames.GIVEN_NAMES]?: string;
    [WebIdFieldsNames.SURNAMES]?: string;
    [WebIdFieldsNames.DATE_OF_BIRTH]?: string;
    [WebIdFieldsNames.EMAIL_ADDRESS]?: string;
    [WebIdFieldsNames.ZIP]?: string;
    [WebIdFieldsNames.CITY]?: string;
    [WebIdFieldsNames.COUNTRY]?: string;
};

const NUMBER_TO_ADD_FOR_NEXT_STEP = 1;

export type ValidFieldsType = {
    name: WebIdFieldsNames;
    element: (control: Control) => React.ReactNode;
    fieldDefaultValue: Date | string;
};

export function PersonalDetails() {
    const { identityVerificationId, setCurrentStep, setOnBackButtonHandler } = useGlobalContext();
    const { value: identityId } = useLocalStorage<string>(LocalStorageKeys.IDENTITY_ID);

    const { data, isLoading } = useWebIdFields(
        typeof identityId === 'string' ? identityId : '',
        identityVerificationId || ''
    );

    const handleVerifyWebIdMutation = () => {
        setCurrentStep((prevState) =>
            prevState !== null ? prevState + NUMBER_TO_ADD_FOR_NEXT_STEP : null
        );
    };

    const {
        error: verifyWebIdMutationError,
        isLoading: isVerifyWebIdMutationLoading,
        verifyWebIdMutation,
    } = useVerifyWebId(handleVerifyWebIdMutation);

    const validFields: ValidFieldsType[] = React.useMemo(
        () =>
            possibleFields.filter(({ name }) =>
                data?.fields.some((fieldName) => name === fieldName)
            ),
        [possibleFields, data?.fields]
    );

    // TODO: rewrite it with useQuery
    // when we do not have any fields to show, we should call verifyWebIdMutation with an empty object
    const verifyWebIdWithoutFieldClaims = React.useCallback(async () => {
        if (!isLoading && !validFields.length) {
            await verifyWebIdMutation({
                fieldClaims: {},
                identityId: typeof identityId === 'string' ? identityId : '',
                identityVerificationId: identityVerificationId || '',
            });
        }
    }, [isLoading, validFields.length, identityId, identityVerificationId]);

    React.useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        verifyWebIdWithoutFieldClaims();
    }, [verifyWebIdWithoutFieldClaims]);

    const defaultValues = React.useMemo(
        () =>
            Object.fromEntries(
                validFields.map(({ fieldDefaultValue, name }) => [name, fieldDefaultValue])
            ),
        [validFields]
    );

    const methods = useForm<PersonalDataFormValues>({
        defaultValues: React.useMemo(() => defaultValues, [defaultValues]),
        resolver: zodResolver(getPersonalDetailsSchema(data?.fields || [])),
    });

    const { control, handleSubmit, reset } = methods;

    React.useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    React.useEffect(() => {
        setOnBackButtonHandler(null);
    }, []);

    const isButtonDisabled = isLoading || !validFields.length || isVerifyWebIdMutationLoading;

    const onSubmitHandler = async (values: PersonalDataFormValues) => {
        let fieldClaimsValues = values;

        if (values?.date_of_birth) {
            const formattedDateOfBirth = transformDate(new Date(values.date_of_birth), DATE_FORMAT);

            fieldClaimsValues = {
                ...values,
                date_of_birth: formattedDateOfBirth,
            };
        }

        await verifyWebIdMutation({
            fieldClaims: fieldClaimsValues,
            identityId: typeof identityId === 'string' ? identityId : '',
            identityVerificationId: identityVerificationId || '',
        });
    };

    const renderProperElement = React.useCallback(() => {
        if (isLoading || !validFields.length) {
            return <HomeLoader />;
        }

        return (
            <>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <PageWrapper>
                            <PersonalDetailsHeading />

                            <PersonalDetailsForm
                                control={control}
                                isButtonDisabled={isButtonDisabled}
                                validFields={validFields}
                                isLoading={isLoading}
                            />
                        </PageWrapper>
                    </form>
                </FormProvider>

                <ErrorAlertWrapper error={verifyWebIdMutationError} />
            </>
        );
    }, [isLoading, validFields, verifyWebIdMutationError, isButtonDisabled]);

    return renderProperElement();
}
