import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { VERIFICATION_ROUTE } from '../../constants';
import lang from '../lang/home';
import { PageWrapper } from '../PageWrapper/PageWrapper';
import { HomePageHeading } from './HomePageHeading';

export function HomePage() {
    const navigate = useNavigate();

    const navigateToVerificationPage = () => navigate(VERIFICATION_ROUTE);

    return (
        <PageWrapper>
            <HomePageHeading />

            <Button
                onClick={navigateToVerificationPage}
                variant="contained"
                sx={{ mt: 4, p: '14px 80px' }}
                data-testid="homeButton"
            >
                {lang.ONBOARDING_BUTTON}
            </Button>
        </PageWrapper>
    );
}
