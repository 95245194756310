import { useQuery } from '@tanstack/react-query';

import { TEN_SECONDS } from '@dock/common';

import { IDENTITY_STATUS_KEY } from '../../keys';
import { selectStatusMapper } from '../mappers/identityStatusMapper';
import { fetchIdentityStatus } from '../methods';

export function useIdentityStatus(identityId: string, shouldRefetchInterval = false) {
    const { data, error, isError, isLoading } = useQuery(
        [IDENTITY_STATUS_KEY, identityId],
        () => fetchIdentityStatus(identityId),
        {
            enabled: !!identityId,
            refetchInterval: () => (shouldRefetchInterval ? TEN_SECONDS : false),
            select: (responseData) => selectStatusMapper(responseData),
        }
    );

    return { data, error, isError, isLoading };
}
