import { useMutation } from '@tanstack/react-query';

import type { EmailVerificationBody } from '@dock/services';

import { DockIdServiceError } from '../../../utils/DockIdServiceError';
import { verifyEmail } from '../methods';

export function useVerifyEmail(handleCallback?: (emailAddress: string) => void) {
    const { data, error, isError, isLoading, mutateAsync } = useMutation<
        unknown,
        DockIdServiceError,
        EmailVerificationBody
    >((payload) => verifyEmail(payload), {
        onSuccess: (_, variables) => {
            if (handleCallback) {
                handleCallback(variables.emailAddress);
            }
        },
    });

    return {
        data,
        error,
        isError,
        isLoading,
        verifyEmailMutation: mutateAsync,
    };
}
