import { useEffect, useCallback } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { useLocalStorage } from '@dock/react-hooks';

import { HomeLoader } from '../../components';
import { HomePage } from '../../components/HomePage';
import {
    HomePageSearchParams,
    LocalStorageKeys,
    VERIFICATION_ROUTE,
    WRONG_URL_ERROR_ROUTE,
} from '../../constants';
import { useRedirectToWrongUrlPage, useGlobalContext } from '../../hooks';
import { useIdentityInitialize, useIdentityInfo } from '../../services';
import { TermsAndConditions } from '../../views/TermsAndConditions/TermsAndConditions';

export function Home() {
    const { setShouldShowNavbar } = useGlobalContext();
    const [searchParams] = useSearchParams();

    const urlPartnerId = searchParams.get(HomePageSearchParams.PARTNER_ID);
    const urlVerificationToken = searchParams.get(HomePageSearchParams.VERIFICATION_REQUEST_TOKEN);
    const urlProductId = searchParams.get(HomePageSearchParams.PRODUCT_ID);

    const shouldRedirectToWrongUrlPage = !urlPartnerId || !urlVerificationToken || !urlProductId;

    useRedirectToWrongUrlPage(shouldRedirectToWrongUrlPage, [
        urlPartnerId,
        urlVerificationToken,
        urlProductId,
    ]);

    const { setValue: setPartnerIdValue } = useLocalStorage<string>(LocalStorageKeys.PARTNER_ID);
    const { setValue: setVerificationTokenValue } = useLocalStorage<string>(
        LocalStorageKeys.VERIFICATION_REQUEST_TOKEN
    );
    const { setValue: setProductIdValue } = useLocalStorage<string>(LocalStorageKeys.PRODUCT_ID);

    useEffect(() => {
        if (urlPartnerId && urlVerificationToken && urlProductId) {
            setPartnerIdValue(urlPartnerId);
            setVerificationTokenValue(urlVerificationToken);
            setProductIdValue(urlProductId);
        }
    }, [urlPartnerId, urlVerificationToken, urlProductId]);

    const { isError: identityInitializeIsError, isLoading: identityInitializeIsLoading } =
        useIdentityInitialize(urlVerificationToken || '', urlPartnerId || '');

    const {
        data: identityInfoData,
        isError: isIdentityInfoIsError,
        isLoading: isIdentityInfoLoading,
    } = useIdentityInfo(urlPartnerId || '', urlProductId || '');

    const { currentStep, setCurrentStep } = useGlobalContext();

    useEffect(() => {
        if (currentStep !== null) {
            setCurrentStep(null);
        }
    }, [currentStep]);

    const isLegalConsentRequired = identityInfoData?.config.isLegalConsentRequired === true;
    const shouldRedirectToVerificationPage =
        typeof identityInfoData?.config.showWelcomeScreen === 'boolean' &&
        identityInfoData?.config.showWelcomeScreen === false;

    const isLoading = identityInitializeIsLoading || isIdentityInfoLoading;
    const isError = identityInitializeIsError || isIdentityInfoIsError;

    useEffect(() => {
        setShouldShowNavbar(!isLoading);
    }, [isLoading]);

    const renderProperElement = useCallback(() => {
        if (isLoading) {
            return <HomeLoader />;
        }

        if (isError) {
            return <Navigate to={WRONG_URL_ERROR_ROUTE} />;
        }

        if (shouldRedirectToVerificationPage) {
            return <Navigate to={VERIFICATION_ROUTE} />;
        }

        if (isLegalConsentRequired) {
            return <TermsAndConditions />;
        }

        return <HomePage />;
    }, [isLoading, isLegalConsentRequired, shouldRedirectToVerificationPage]);

    return renderProperElement();
}
