import { flow, pipe } from 'fp-ts/lib/function';
import { of } from 'fp-ts/lib/Task';
import * as TE from 'fp-ts/lib/TaskEither';

import type { Response, RequestType, Headers, Request } from '@dock/http-request';

import { GenericServiceError } from '@dock/common';

type ErrorHandlerType = (error: unknown) => GenericServiceError<unknown>;

export type RequestParams<P = Record<string, unknown>> = {
    params?: string | undefined;
    payload?: P | undefined;
    headers?: Headers;
};

export type ResponseWithContinuationToken<R> = {
    continuationToken: string | undefined;
    data: R;
};

export type FetchAndHandleType<P, T> = (
    req: RequestParams<P>
) => TE.TaskEither<GenericServiceError<unknown>, T>;

export type Getter<R> = <P>({
    headers,
    params,
    payload,
}: RequestParams<P>) => Promise<R | GenericServiceError<unknown>>;

export type RequestAndUnwrap<V extends keyof Request, R> = (v: Request[V]) => Getter<R>;

export const requestFlow =
    (errorHandler: ErrorHandlerType) =>
    (endpoint: string) =>
    <T>(handler: <R>(res: Response<R>) => T) =>
    (verb: RequestType) =>
    <P>({
        headers,
        params,
        payload,
    }: RequestParams<P>): TE.TaskEither<GenericServiceError<unknown>, T> => {
        const req = flow(verb, TE.map(handler), TE.mapLeft(errorHandler));
        const reqParams = params ? `?${params}` : '';

        return req(`${endpoint}${reqParams}`, payload, headers);
    };

/**
 * @deprecated
 */
export const getData = <R>(res: Response<unknown>) => res.data as R;

// We can extract here more important headers in the future
export const getDataAndHeaders = <R>(res: Response<unknown>) =>
    ({
        continuationToken: res.headers['x-continuation-token'],
        data: res.data,
    }) as R;

export const getFromMonad = <T>(
    fetch: TE.TaskEither<GenericServiceError<unknown>, T>
): Promise<GenericServiceError<unknown> | T> => {
    const res = pipe(fetch, TE.getOrElseW(of));
    return res();
};

export const unwrapService =
    <Res>(fetch: FetchAndHandleType<RequestParams, Res>) =>
    <P>({
        headers,
        params,
        payload,
    }: RequestParams<P>): Promise<Res | GenericServiceError<unknown>> =>
        getFromMonad(
            fetch({
                params,
                ...(payload ? { payload } : {}),
                headers,
            })
        );
