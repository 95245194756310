import { VerificationStatusPart, VerifyStatus } from '@dock/services';

type VerificationStatusesType =
    | {
          id: string;
          status: VerificationStatusPart;
          verifier: VerifyStatus;
      }
    | undefined;

export const getCurrentStep = (
    possibleSteps: VerifyStatus[] | undefined,
    verificationStatuses: VerificationStatusesType
): number | null => {
    const step = possibleSteps?.findIndex((name) => name === verificationStatuses?.verifier);

    const isWebIdNewStep =
        verificationStatuses?.verifier === VerifyStatus.WEB_ID &&
        verificationStatuses?.status === VerificationStatusPart.NEW;

    const isStepUnavailable = step === undefined || step === -1;

    if (isWebIdNewStep) {
        // we manually add one step to the stepper, so we have to subtract one
        return isStepUnavailable ? null : step - 1;
    }

    const isWebIdDoneStep =
        verificationStatuses?.verifier === VerifyStatus.WEB_ID &&
        verificationStatuses?.status === VerificationStatusPart.COMPLETED;

    if (isWebIdDoneStep) {
        // we manually add one step to the stepper, so we have to add one
        return isStepUnavailable ? null : step + 1;
    }

    return isStepUnavailable ? null : step;
};
