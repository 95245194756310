import { useEffect, useCallback, JSX } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLocalStorage } from '@dock/react-hooks';
import { VerifyStatus } from '@dock/services';

import { HomeLoader } from '../../components';
import { HomePage } from '../../components/HomePage';
import { LocalStorageKeys, WEB_ID_VERIFICATION_ROUTE } from '../../constants';
import {
    useRedirectToFailedRegistrationPage,
    useRedirectToSuccessPage,
    useRedirectToWrongUrlPage,
    useSteps,
    useGlobalContext,
} from '../../hooks';
import { EmailStep } from '../../views/EmailStep';
import { PersonalDetails } from '../../views/PersonalDetails/PersonalDetails';
import { PhoneStep } from '../../views/PhoneStep';

export function Verification() {
    const navigate = useNavigate();

    const { activeSteps, currentStep, setIdentityVerificationId } = useGlobalContext();

    const { value: identityId } = useLocalStorage<string>(LocalStorageKeys.IDENTITY_ID);

    const isIdentityIdProvided = typeof identityId === 'string';

    useRedirectToWrongUrlPage(!isIdentityIdProvided, [isIdentityIdProvided]);

    const {
        identityStatusData,
        identityStatusError,
        identityStatusIsError,
        identityStatusIsLoading,
    } = useSteps();

    useRedirectToFailedRegistrationPage(
        identityStatusData?.isRegistrationFailedOrCanceled || false,
        [identityStatusData?.isRegistrationFailedOrCanceled]
    );

    useRedirectToWrongUrlPage(!!identityStatusData?.shouldRedirectToErrorPage, [
        identityStatusData?.shouldRedirectToErrorPage,
    ]);

    useRedirectToWrongUrlPage(identityStatusIsError, [identityStatusIsError], identityStatusError);

    useRedirectToSuccessPage(!!identityStatusData?.shouldRedirectToSuccessPage, [
        identityStatusData?.shouldRedirectToSuccessPage,
    ]);

    // eslint-disable-next-line
    // @ts-ignore
    // Should map all steps except of WEB_ID as WebIdVerification step now renders outside current file.
    // This may be changed later
    const mappedVerifyStatusToComponent: Record<VerifyStatus, JSX.Element> = {
        [VerifyStatus.DOCK_ID]: <PersonalDetails />,
        [VerifyStatus.EMAIL]: <EmailStep status={identityStatusData?.currentStep?.status} />,
        [VerifyStatus.NONE]: <HomePage />,
        [VerifyStatus.PHONE]: <PhoneStep status={identityStatusData?.currentStep?.status} />,
    };

    useEffect(() => {
        if (
            !identityStatusData?.identityVerificationsIds ||
            !identityStatusData?.currentStep ||
            currentStep === null
        ) {
            return;
        }

        const { identityVerificationsIds } = identityStatusData;

        const currentIdentityVerificationId = identityVerificationsIds[currentStep]?.id;

        setIdentityVerificationId(currentIdentityVerificationId);
    }, [identityStatusData, currentStep]);

    const renderCorrectStep = useCallback(() => {
        if (identityStatusIsLoading) {
            return <HomeLoader />;
        }

        if (activeSteps.length && currentStep !== null && !!activeSteps[currentStep]?.name) {
            if (activeSteps[currentStep]?.name === VerifyStatus.WEB_ID) {
                navigate(WEB_ID_VERIFICATION_ROUTE);
            }

            return mappedVerifyStatusToComponent[
                activeSteps[currentStep]?.name || VerifyStatus.NONE
            ];
        }

        return <HomeLoader />;
    }, [identityStatusIsLoading, currentStep, activeSteps]);

    return renderCorrectStep();
}
