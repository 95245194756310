import { Box, Typography } from '@mui/material';

import lang from '../lang/legalNotice';

export function LegalNoticeContent() {
    return (
        <Box sx={{ px: 11, py: 8 }}>
            <Typography component="h1" sx={{ fontSize: '40px', lineHeight: '54.64px', mb: 3 }}>
                {lang.header}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                    mb: 2,
                }}
            >
                <Typography variant="h4">{lang.firstRowContent}</Typography>

                <Typography variant="h4">{lang.secondRowContent}</Typography>

                <Typography variant="h4">{lang.thirdRowContent}</Typography>

                <Typography variant="h4">
                    {lang.fourthRowContent}{' '}
                    <Typography
                        component="a"
                        variant="h4"
                        href="mailto:contact@dock.financial"
                        sx={{ color: 'primary.main', fontWeight: 'inherit' }}
                    >
                        contact@dock.financial
                    </Typography>
                </Typography>
            </Box>
            <Typography variant="h4">{lang.additionalInfo}</Typography>
        </Box>
    );
}
