import React from 'react';

import type { SubStepsType } from '../utils';

import { useGlobalContext } from './useGlobalContext';

const SECOND_STEP_INDEX = 1;
const FIRST_STEP_INDEX = 0;

export const useHandleBackButton = (
    currentStep: SubStepsType,
    setCurrentStep: React.Dispatch<React.SetStateAction<SubStepsType>>
) => {
    const { setOnBackButtonHandler } = useGlobalContext();

    const goBackHandler = () => {
        setCurrentStep(FIRST_STEP_INDEX);
    };

    React.useEffect(() => {
        if (currentStep === SECOND_STEP_INDEX) {
            setOnBackButtonHandler(() => goBackHandler);
        } else {
            setOnBackButtonHandler(null);
        }
    }, [currentStep]);
};
