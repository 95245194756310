import React from 'react';
import { useForm } from 'react-hook-form';

import { useLocalStorage } from '@dock/react-hooks';

import type { SubStepsType } from '../../utils/functions/mapStatusToCurrentSubStep';

import { ErrorAlertWrapper } from '../../components/ErrorAlertWrapper/ErrorAlertWrapper';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { PhoneNumberVerificationHeading } from '../../components/PhoneVerification/PhoneNumberVerificationHeading';
import { PhoneVerificationFormContent } from '../../components/PhoneVerification/PhoneVerificationFormContent/PhoneVerificationFormContent';
import { LocalStorageKeys } from '../../constants';
import { usePhoneNumberContext, useGlobalContext } from '../../hooks';
import { useVerifyPhoneNumber } from '../../services';
import { formatPhoneNumberCountryCode } from '../../utils';
import { VerifyFieldsEnum } from '../EmailVerification/constants/enums';

type PhoneNumberVerificationType = {
    setCurrentStep: React.Dispatch<React.SetStateAction<SubStepsType>>;
};

export type PhoneVerificationFormValues = {
    phoneNumber: string;
};

export function PhoneNumberVerification({ setCurrentStep }: PhoneNumberVerificationType) {
    const { setPhoneNumber } = usePhoneNumberContext();

    const defaultValues: PhoneVerificationFormValues = {
        [VerifyFieldsEnum.PHONE_NUMBER]: '',
    };

    const { control, handleSubmit, watch } = useForm<PhoneVerificationFormValues>({
        defaultValues,
    });

    const handleVerifyPhoneNumberMutationCallback = (phoneNumber: string) => {
        setPhoneNumber(phoneNumber);
        setCurrentStep(1);
    };

    const { error, isLoading, verifyPhoneNumberMutation } = useVerifyPhoneNumber(
        handleVerifyPhoneNumberMutationCallback
    );
    const { identityVerificationId } = useGlobalContext();
    const { value: identityId } = useLocalStorage<string>(LocalStorageKeys.IDENTITY_ID);

    const onSubmitHandler = async (values: PhoneVerificationFormValues) => {
        const formattedPhoneNumber = formatPhoneNumberCountryCode(values.phoneNumber);

        await verifyPhoneNumberMutation({
            identityId: typeof identityId === 'string' ? identityId : '',
            identityVerificationId: identityVerificationId || '',
            mobileNumber: formattedPhoneNumber,
        });
    };

    const isSubmitButtonDisabled = !watch(VerifyFieldsEnum.PHONE_NUMBER).length || isLoading;

    return (
        <>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <PageWrapper>
                    <PhoneNumberVerificationHeading />
                    <PhoneVerificationFormContent
                        control={control}
                        isSubmitButtonDisabled={isSubmitButtonDisabled}
                        isLoading={isLoading}
                    />
                </PageWrapper>
            </form>

            <ErrorAlertWrapper error={error} />
        </>
    );
}
