import type { Control } from 'react-hook-form';

import { OTP_CODE_LENGTH_5 } from '@dock/common';
import { useLocalStorage } from '@dock/react-hooks';

import type { PhoneConfirmationFields } from '../../../views/PhoneNumberConfirmation/PhoneNumberConfirmation';

import { LocalStorageKeys } from '../../../constants';
import { useGlobalContext, usePhoneNumberContext } from '../../../hooks';
import { useVerifyPhoneNumber } from '../../../services';
import { formatPhoneNumberCountryCode } from '../../../utils';
import { ConfirmationStepForm } from '../../ConfirmationStepForm/ConfirmationStepForm';
import { ErrorAlertWrapper } from '../../ErrorAlertWrapper/ErrorAlertWrapper';
import phoneConfirmation from '../../lang/phoneConfirmation';

type PhoneNumberConfirmationFormContentType = {
    control: Control<PhoneConfirmationFields, unknown>;
    isSubmitButtonDisabled: boolean;
    isLoading: boolean;
};

export function PhoneNumberConfirmationFormContent({
    control,
    isLoading,
    isSubmitButtonDisabled,
}: PhoneNumberConfirmationFormContentType) {
    const { error, verifyPhoneNumberMutation } = useVerifyPhoneNumber();
    const { identityVerificationId } = useGlobalContext();
    const { phoneNumber } = usePhoneNumberContext();

    const { value: identityId } = useLocalStorage<string>(LocalStorageKeys.IDENTITY_ID);

    const onResendPhoneNumberHandler = async () => {
        if (!phoneNumber) {
            return;
        }

        const formattedPhoneNumber = formatPhoneNumberCountryCode(phoneNumber);

        await verifyPhoneNumberMutation({
            identityId: typeof identityId === 'string' ? identityId : '',
            identityVerificationId: identityVerificationId || '',
            mobileNumber: formattedPhoneNumber || '',
        });
    };

    return (
        <>
            <ConfirmationStepForm<PhoneConfirmationFields>
                confirmationButtonText={phoneConfirmation.PHONE_NUMBER_CONFIRMATION_BUTTON}
                control={control}
                resendCallback={onResendPhoneNumberHandler}
                isSubmitButtonDisabled={isSubmitButtonDisabled}
                isLoading={isLoading}
                maxLength={OTP_CODE_LENGTH_5}
            />

            <ErrorAlertWrapper error={error} />
        </>
    );
}
