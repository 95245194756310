import { Typography } from '@mui/material';
import { useEffect } from 'react';

import { SuccessSvg, FailureSvg } from '@dock/react-components';

import { useGlobalContext, useSteps } from '../../hooks';
import { PageWrapper } from '../PageWrapper/PageWrapper';

export enum Status {
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

type StatusPageProps = {
    headingContent: string;
    additionalInfoContent: string;
    status: Status;
};

const icons = {
    [Status.FAILURE]: <FailureSvg />,
    [Status.SUCCESS]: <SuccessSvg />,
};

export function StatusPage({ additionalInfoContent, headingContent, status }: StatusPageProps) {
    const { setShouldShowNavbar } = useGlobalContext();

    const properIcon = icons[status];

    useSteps();

    useEffect(() => {
        setShouldShowNavbar(true);
    }, []);

    return (
        <PageWrapper sx={{ height: '100vh', mt: '0' }}>
            {properIcon}
            <Typography
                component="h1"
                variant="inherit"
                sx={{ fontSize: '56px', lineHeight: '76.5px', mb: 1 }}
            >
                {headingContent}
            </Typography>
            <Typography variant="h4" align="center">
                {additionalInfoContent}
            </Typography>
        </PageWrapper>
    );
}
