import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { FAILURE_ROUTE } from '../constants';

export const useRedirectToFailedRegistrationPage = (
    conditionToRedirect: boolean,
    dependencies: unknown[]
) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (conditionToRedirect) {
            navigate(FAILURE_ROUTE);
        }
    }, dependencies);
};
