import { GenericServiceError } from '@dock/common';
import { PhoneNumberVerificationBody } from '@dock/services';

import { postVerifyPhoneNumber } from '../../client';

export const verifyPhoneNumber = async (payload: PhoneNumberVerificationBody): Promise<unknown> => {
    const errorOrResponse = await postVerifyPhoneNumber({ payload });

    if (errorOrResponse instanceof GenericServiceError) {
        throw errorOrResponse;
    }

    return errorOrResponse;
};
