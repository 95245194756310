import { VerifyStatus } from '@dock/services';

import navbarLang from '../components/lang/navbar';
import { ExtendedStepType } from './types';

export const allStepperSteps: ExtendedStepType[] = [
    {
        isOptional: false,
        label: navbarLang.EMAIL_VERIFICATION,
        name: VerifyStatus.EMAIL,
    },
    {
        isOptional: false,
        label: navbarLang.PHONE_NUMBER_VERIFICATION,
        name: VerifyStatus.PHONE,
    },
    {
        isOptional: false,
        label: navbarLang.PERSONAL_DETAILS,
        name: VerifyStatus.DOCK_ID,
    },
    {
        isOptional: false,
        label: navbarLang.WEB_ID_VERIFICATION,
        name: VerifyStatus.WEB_ID,
    },
];
