import { useMutation } from '@tanstack/react-query';

import type { WebIdVerificationBody, WebIdVerificationResponse } from '@dock/services';

import { DockIdServiceError } from '../../../utils/DockIdServiceError';
import { verifyWebId } from '../methods';

export function useVerifyWebId(handleCallback?: () => void) {
    const { data, error, isError, isLoading, mutateAsync } = useMutation<
        WebIdVerificationResponse,
        DockIdServiceError,
        WebIdVerificationBody
    >((payload) => verifyWebId(payload), {
        onSuccess: () => handleCallback && handleCallback(),
    });

    return {
        data,
        error,
        isError,
        isLoading,
        verifyWebIdMutation: mutateAsync,
    };
}
