import { Box, Link } from '@mui/material';

import lang from '../../components/lang/termsAndConditions';

export function TermsAndConditionsLabel() {
    return (
        <Box sx={{ fontSize: '16px' }} data-testid="termsAndConditionsLabel">
            <span>{lang.CHECKBOX_TEXT} </span>
            <Link href="/dummy.pdf" download="terms-end-conditions">
                {lang.TERMS_AND_CONDITIONS}
            </Link>
            <span>.</span>
        </Box>
    );
}
