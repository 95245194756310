import { useQuery } from '@tanstack/react-query';

import type { ActiveWebIdVerificationResponse } from '@dock/services';

import { DockIdServiceError } from '../../../utils/DockIdServiceError';
import { ACTIVE_WEB_ID_KEY } from '../../keys';
import { fetchActiveWebId } from '../methods';

export function useActiveWebId(identityId: string, identityVerificationId: string) {
    return useQuery<
        ActiveWebIdVerificationResponse,
        DockIdServiceError,
        ActiveWebIdVerificationResponse
    >(
        [ACTIVE_WEB_ID_KEY, identityId, identityVerificationId],
        () => fetchActiveWebId(identityId, identityVerificationId),
        { enabled: !!identityId && !!identityVerificationId }
    );
}
