import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';

import { useLocalStorage } from '@dock/react-hooks';
import { verifyEmailSchema } from '@dock/validation';

import type { SubStepsType } from '../../utils/functions/mapStatusToCurrentSubStep';

import { EmailVerificationHeading, EmailVerificationFormContent } from '../../components';
import { ErrorAlertWrapper } from '../../components/ErrorAlertWrapper/ErrorAlertWrapper';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { LocalStorageKeys } from '../../constants';
import { useEmailContext, useGlobalContext } from '../../hooks';
import { useVerifyEmail } from '../../services';
import { VerifyFieldsEnum } from './constants/enums';

export type EmailVerificationFormValues = {
    emailAddress: string;
};

type EmailVerificationType = {
    setCurrentStep: React.Dispatch<React.SetStateAction<SubStepsType>>;
};

export function EmailVerification({ setCurrentStep }: EmailVerificationType) {
    const { email, setEmail } = useEmailContext();

    const defaultValues: EmailVerificationFormValues = {
        [VerifyFieldsEnum.EMAIL_ADDRESS]: email || '',
    };

    const { control, handleSubmit, watch } = useForm<EmailVerificationFormValues>({
        defaultValues,
        resolver: zodResolver(verifyEmailSchema),
    });

    const handleVerifyEmailMutationCallback = (emailAddress: string) => {
        setEmail(emailAddress);
        setCurrentStep(1);
    };

    const { error, isLoading, verifyEmailMutation } = useVerifyEmail(
        handleVerifyEmailMutationCallback
    );
    const { identityVerificationId } = useGlobalContext();
    const { value: identityId } = useLocalStorage<string>(LocalStorageKeys.IDENTITY_ID);

    const onSubmitHandler = async (values: EmailVerificationFormValues) => {
        setEmail(values.emailAddress);
        await verifyEmailMutation({
            emailAddress: values.emailAddress,
            identityId: typeof identityId === 'string' ? identityId : '',
            identityVerificationId: identityVerificationId || '',
        });
    };

    const isSubmitButtonDisabled = !watch(VerifyFieldsEnum.EMAIL_ADDRESS).length || isLoading;

    return (
        <>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <PageWrapper>
                    <EmailVerificationHeading />

                    <EmailVerificationFormContent
                        control={control}
                        isSubmitButtonDisabled={isSubmitButtonDisabled}
                        isLoading={isLoading}
                    />
                </PageWrapper>
            </form>
            <ErrorAlertWrapper error={error} />
        </>
    );
}
