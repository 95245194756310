import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useLocalStorage } from '@dock/react-hooks';

import { HomePageSearchParams, LocalStorageKeys } from '../../constants';

type Props = {
    children: React.ReactNode;
};

// Before we start new verification process we need to clear whole storage
export function CheckIfNewProcessStarted({ children }: Props) {
    const [searchParams] = useSearchParams();

    // Get verification param from URL
    const urlVerificationToken = searchParams.get(HomePageSearchParams.VERIFICATION_REQUEST_TOKEN);

    // Get verification param from storage
    const { value: verificationRequestToken } = useLocalStorage<string>(
        LocalStorageKeys.VERIFICATION_REQUEST_TOKEN
    );

    const isNewProcessStarted =
        typeof urlVerificationToken === 'string' &&
        typeof verificationRequestToken === 'string' &&
        urlVerificationToken !== verificationRequestToken;

    useEffect(() => {
        if (isNewProcessStarted) {
            localStorage.clear();
        }
    }, [isNewProcessStarted]);

    return children;
}
