const authMessageError =
    'Your access and refresh tokens may have expired. Please, try to refresh the page. If you continue to experience issues, please contact our support team for assistance.';
const validationErrorMessage =
    'Some input fields may not have been filled in correctly. Please, try to refill the inputs or refresh the page. If you continue to experience issues, please contact our support team for assistance.';
const wrongParamErrorTitle = 'Backend error. Wrong URL parameter.';
const lackOfParamErrorTitle = 'Backend error. Lack of URL parameter.';
const serviceErrorTitle = 'Server error.';
const authTitleError = 'Authentication error';
const validationErrorTitle = 'Validation error.';
const wrongOtpCode = 'You passed wrong OTP code. Please, try again.';
const resendPhoneCode = 'You already sent the code. Please, try again in 60 seconds.';

export type ErrorType = { title: string; message?: string };

export type ExpectedErrorsType = {
    [key: string]: ErrorType;
};

export const expectedErrors: ExpectedErrorsType = {
    'evsoc-11001': { message: authMessageError, title: authTitleError },
    'evsoc-11002': {
        message: validationErrorMessage,
        title: validationErrorTitle,
    },
    'evsoc-11003': {
        message: validationErrorMessage,
        title: validationErrorTitle,
    },
    'evsoc-11004': { title: serviceErrorTitle },
    'evvoc-11001': { message: authMessageError, title: authTitleError },
    'evvoc-11002': {
        message: validationErrorMessage,
        title: validationErrorTitle,
    },
    'evvoc-11003': {
        message: validationErrorMessage,
        title: validationErrorTitle,
    },
    'evvoc-11004': { message: wrongOtpCode, title: serviceErrorTitle },
    'igwhs-11001': { message: authMessageError, title: authTitleError },
    'igwhs-11002': { title: wrongParamErrorTitle },
    'igwhs-11003': { title: lackOfParamErrorTitle },
    'igwhs-11004': { title: wrongParamErrorTitle },
    'igwhs-11005': { title: serviceErrorTitle },
    'igwii-11001': { title: lackOfParamErrorTitle },
    'igwii-11002': { title: wrongParamErrorTitle },
    'igwii-11003': { title: lackOfParamErrorTitle },
    'igwii-11004': { title: serviceErrorTitle },
    'pvsoc-11001': { message: authMessageError, title: authTitleError },
    'pvsoc-11002': {
        message: validationErrorMessage,
        title: validationErrorTitle,
    },
    'pvsoc-11003': {
        message: validationErrorMessage,
        title: validationErrorTitle,
    },
    'pvsoc-11004': { title: wrongParamErrorTitle },
    'pvsoc-11005': { message: resendPhoneCode, title: serviceErrorTitle },
    'pvvoc-11001': { message: authMessageError, title: authTitleError },
    'pvvoc-11002': {
        message: validationErrorMessage,
        title: validationErrorTitle,
    },
    'pvvoc-11003': {
        message: validationErrorMessage,
        title: validationErrorTitle,
    },
    'pvvoc-11004': { title: serviceErrorTitle },
    'wvgaw-11001': { message: authMessageError, title: authTitleError },
    'wvgaw-11002': { title: wrongParamErrorTitle },
    'wvgaw-11003': { title: serviceErrorTitle },
    'wvgiw-11001': { message: authMessageError, title: authTitleError },
    'wvgiw-11002': {
        message: validationErrorMessage,
        title: validationErrorTitle,
    },
    'wvgiw-11003': {
        message: validationErrorMessage,
        title: validationErrorTitle,
    },
    'wvgiw-11004': {
        message: validationErrorMessage,
        title: validationErrorTitle,
    },
    'wvgmr-11001': { message: authMessageError, title: authTitleError },
    'wvgmr-11002': { title: wrongParamErrorTitle },
    'wvgmr-11003': { title: serviceErrorTitle },
    'wvwfc-11004': { title: serviceErrorTitle },
    'wvwvc-11001': {
        message: validationErrorMessage,
        title: validationErrorTitle,
    },
    'wvwvc-11002': { title: wrongParamErrorTitle },
    'wvwvc-11004': { title: serviceErrorTitle },
};
