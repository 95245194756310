import { useMemo } from 'react';

import { VerificationStatusPart } from '@dock/services';

import { allStepperSteps } from '../constants';
import { ExtendedStepType } from '../constants/types';
import { IdentityStatusDataReturnType } from '../services/queries/mappers/identityStatusMapper';

export const useActiveSteps = (
    identityStatusData: IdentityStatusDataReturnType | undefined
): ExtendedStepType[] =>
    useMemo(() => {
        const getIsErrorStatus = (step: ExtendedStepType) =>
            !!identityStatusData?.verificationStatuses
                .find(({ verifier }) => step.name === verifier)
                ?.status.includes(VerificationStatusPart.FAILED || VerificationStatusPart.CANCELED);

        return allStepperSteps
            .filter(({ name }) => identityStatusData?.possibleStepperSteps.includes(name))
            .map((step) => ({
                ...step,
                isError: getIsErrorStatus(step),
            }));
    }, [identityStatusData]);
