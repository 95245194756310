import { QueryClient } from '@tanstack/react-query';
import * as E from 'fp-ts/lib/Either';

import { ACCESS_TOKEN, DockError, safeParseJson } from '@dock/common';
import { is401Error } from '@dock/services';

import { LocalStorageKeys } from '../../constants';
import { getIdentityCredentials } from './consumerService';

export const FAILED_REFRESH_TOKEN_EVENT = 'refreshTokenFailed';

export const emitErrorEvent = () => {
    window.dispatchEvent(new Event(FAILED_REFRESH_TOKEN_EVENT));
};

const setToLocalStorage = (accessToken: string) => {
    localStorage.setItem(ACCESS_TOKEN, JSON.stringify(accessToken));
};

const getParamFromLocalStorage = (paramName: LocalStorageKeys) => {
    const param = localStorage.getItem(paramName);
    const parsedValue = safeParseJson<string>(param || '');

    if (E.isLeft(parsedValue)) {
        return null;
    }

    return parsedValue.right || null;
};

const updateToken = async () => {
    const verificationRequestToken = getParamFromLocalStorage(
        LocalStorageKeys.VERIFICATION_REQUEST_TOKEN
    );
    const partnerId = getParamFromLocalStorage(LocalStorageKeys.PARTNER_ID);

    if (!verificationRequestToken || !partnerId) {
        return Promise.reject();
    }

    const errorOrResponse = await getIdentityCredentials(verificationRequestToken, partnerId);

    if (errorOrResponse instanceof DockError) {
        emitErrorEvent();
        return Promise.reject();
    }

    setToLocalStorage(errorOrResponse.accessToken);

    return Promise.resolve();
};

export const queryClient = new QueryClient({
    defaultOptions: {
        mutations: {
            onSettled: async (_, error) => {
                if (is401Error(error)) {
                    await updateToken();
                }
            },
        },
        queries: {
            cacheTime: 0,
            onError: async (error) => {
                if (is401Error(error)) {
                    await updateToken()
                        .then(async () => {
                            await queryClient.refetchQueries();
                        })
                        .catch(async () => {
                            await queryClient.cancelQueries();
                        });
                }
            },
            refetchOnWindowFocus: false,
            retry: 2,
            staleTime: 0,
        },
    },
});
