import React, { createContext, useContext, useState } from 'react';

import { DockError } from '@dock/common';

import { ExtendedStepType } from '../constants/types';

type OnBackButtonHandlerType = () => void;

export type GlobalContextType = {
    identityVerificationId: string | undefined;
    setIdentityVerificationId: React.Dispatch<React.SetStateAction<string | undefined>>;
    currentStep: number | null;
    setCurrentStep: React.Dispatch<React.SetStateAction<number | null>>;
    activeSteps: ExtendedStepType[];
    setActiveSteps: React.Dispatch<React.SetStateAction<ExtendedStepType[]>>;
    onBackButtonHandler: OnBackButtonHandlerType | null;
    setOnBackButtonHandler: React.Dispatch<React.SetStateAction<OnBackButtonHandlerType | null>>;
    shouldShowNavbar: boolean;
    setShouldShowNavbar: React.Dispatch<React.SetStateAction<boolean>>;
};

export const globalContextInitialValues = {
    activeSteps: [],
    currentStep: null,
    identityVerificationId: undefined,
    onBackButtonHandler: null,
    setActiveSteps: () => {},
    setCurrentStep: () => {},
    setIdentityVerificationId: () => {},
    setOnBackButtonHandler: () => {},
    setShouldShowNavbar: () => {},
    shouldShowNavbar: true,
};

export const GlobalContext = createContext<GlobalContextType>(globalContextInitialValues);

export function GlobalProvider({ children }: { children: React.ReactNode }) {
    const [identityVerificationId, setIdentityVerificationId] = React.useState<string | undefined>(
        undefined
    );

    const [shouldShowNavbar, setShouldShowNavbar] = useState(true);

    const [currentStep, setCurrentStep] = React.useState<number | null>(null);

    const [activeSteps, setActiveSteps] = React.useState<ExtendedStepType[]>([]);

    const [onBackButtonHandler, setOnBackButtonHandler] =
        React.useState<OnBackButtonHandlerType | null>(null);

    const values = React.useMemo(
        () => ({
            activeSteps,
            currentStep,
            identityVerificationId,
            onBackButtonHandler,
            setActiveSteps,
            setCurrentStep,
            setIdentityVerificationId,
            setOnBackButtonHandler,
            setShouldShowNavbar,
            shouldShowNavbar,
        }),
        [
            identityVerificationId,
            setIdentityVerificationId,
            currentStep,
            setCurrentStep,
            activeSteps,
            setActiveSteps,
            onBackButtonHandler,
            setOnBackButtonHandler,
            shouldShowNavbar,
            setShouldShowNavbar,
        ]
    );

    return <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>;
}

export const useGlobalContext = () => {
    const context = useContext(GlobalContext);

    if (context === undefined) {
        throw new DockError('useGlobalContext must be used within an GlobalProvider');
    }

    return context;
};
