import { Box, Button, CircularProgress } from '@mui/material';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { Control, Controller } from 'react-hook-form';

import { VerifyFieldsEnum } from '../../../views/EmailVerification/constants/enums';
import phoneVerification from '../../lang/phoneVerification';

type PhoneVerificationFormContentType = {
    // any due to incompatibility between react-hook-form and phone number input
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<any, unknown>;
    isSubmitButtonDisabled: boolean;
    isLoading: boolean;
};

export function PhoneVerificationFormContent({
    control,
    isLoading,
    isSubmitButtonDisabled,
}: PhoneVerificationFormContentType) {
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                justifyContent: 'center',
                maxWidth: '400px',
                mt: 5,
                width: '100%',
            }}
        >
            <Controller
                name={VerifyFieldsEnum.PHONE_NUMBER}
                control={control}
                rules={{ validate: matchIsValidTel }}
                render={({ field, fieldState: { invalid } }) => (
                    <MuiTelInput
                        {...field}
                        label={phoneVerification.PHONE_NUMBER_LABEL}
                        disableFormatting
                        helperText={invalid ? phoneVerification.PHONE_NUMBER_ERROR : ''}
                        error={invalid}
                        sx={{ width: '100%' }}
                        defaultCountry="DE"
                        forceCallingCode
                        data-testid="phoneNumberInput"
                    />
                )}
            />

            <Button
                variant="contained"
                type="submit"
                sx={{ px: 10, py: 1.75 }}
                data-testid="verifyPhoneNumber"
                disabled={isSubmitButtonDisabled}
                endIcon={
                    isLoading && (
                        <CircularProgress data-testid="loadingIcon" color="inherit" size="1.2rem" />
                    )
                }
            >
                {phoneVerification.VERIFY_PHONE_NUMBER}
            </Button>
        </Box>
    );
}
