import { flow } from 'fp-ts/lib/function';

import { handleErrors } from '../../../../errorService';
import { requestFlow, getData, RequestAndUnwrap, unwrapService } from '../../../../utilities';

export const phoneVerificationCallbackPostHandler = requestFlow(handleErrors);

export const postVerifyPhoneNumberCallback = () =>
    phoneVerificationCallbackPostHandler(`/v0/verification/mobile-number-verification-callback`);

const writeAndMap = () => postVerifyPhoneNumberCallback()(getData);

export const verifyPhoneNumberCallback: RequestAndUnwrap<'post', unknown> = flow(
    writeAndMap(),
    unwrapService
);
