import { PLUS_SIGN } from '@dock/common';

import phoneConfirmation from '../../lang/phoneConfirmation';
import { StepHeading } from '../../StepHeading';

type PhoneNumberConfirmationHeadingTypes = {
    providedPhoneNumber: string | undefined;
};

export function PhoneNumberConfirmationHeading({
    providedPhoneNumber,
}: PhoneNumberConfirmationHeadingTypes) {
    const transformedPhoneNumber = providedPhoneNumber?.replace('00', PLUS_SIGN);

    return (
        <StepHeading
            headerText={phoneConfirmation.HEADER_TEXT}
            contentText={`${phoneConfirmation.CONTENT_TEXT} ${transformedPhoneNumber || '-'}.`}
            headerTestId="phoneNumberConfirmationHeading"
            contentTestId="phoneNumberConfirmationContent"
        />
    );
}
