export default {
    CITY: 'City of residence',
    CONTENT_TEXT:
        "Please make sure to provide data accordingly to your legal documents and ensure that you don't include any special symbols.",
    COUNTRY: 'Country of residence',
    DATE_OF_BIRTH: 'Date of birth',
    DIVERSE: 'Diverse',
    EMAIL_ADDRESS: 'Email address',
    FEMALE: 'Female',
    GENDER: 'Gender',
    GIVEN_NAME: 'Given name(s)',
    HEADER_TEXT: 'Personal details',
    MALE: 'Male',
    SUBMIT_BUTTON: 'Confirm personal details',
    SURNAME: 'Surname(s)',
    ZIP_CODE: 'Postal code of residence',
};
