import { getIdentityCredentials } from '../../client';

export const fetchIdentityInitialize = async (
    verificationRequestToken: string,
    partnerId: string
) => {
    const identityInitializeOrError = await getIdentityCredentials(
        verificationRequestToken,
        partnerId
    );

    if (identityInitializeOrError instanceof Error) {
        throw identityInitializeOrError;
    }
    return identityInitializeOrError;
};
