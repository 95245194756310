import { Box } from '@mui/material';

import { Skeleton } from '@dock/react-components';

const NUMBER_OF_STEPPER_SKELETONS = 4;

export function NavbarSkeleton() {
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                height: '112px',
                width: '100%',
            }}
            data-testid="navbarSkeletons"
        >
            <Box sx={{ ml: 11, mr: 17.5 }}>
                <Skeleton width="172px" height="40px" />
            </Box>

            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-around',
                    width: '100%',
                }}
            >
                {Array.from({ length: NUMBER_OF_STEPPER_SKELETONS }).map((_, index) => (
                    <Box
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            gap: 1,
                            justifyContent: 'space-around',
                        }}
                    >
                        <Skeleton variant="circular" width="24px" height="24px" />
                        <Skeleton width="124px" height="14px" />
                    </Box>
                ))}
            </Box>
        </Box>
    );
}
