import { useMutation } from '@tanstack/react-query';

import type { EmailVerificationCallbackBody } from '@dock/services';

import { DockIdServiceError } from '../../../utils/DockIdServiceError';
import { verifyEmailCallback } from '../methods';

export function useVerifyEmailCallback(handleCallback?: () => void) {
    const { data, error, isError, isLoading, mutateAsync } = useMutation<
        unknown,
        DockIdServiceError,
        EmailVerificationCallbackBody
    >((payload) => verifyEmailCallback(payload), {
        onSuccess: () => {
            if (handleCallback) {
                handleCallback();
            }
        },
    });

    return {
        data,
        error,
        isError,
        isLoading,
        verifyEmailCallbackMutation: mutateAsync,
    };
}
