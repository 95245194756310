import { flow } from 'fp-ts/lib/function';

import type { IdentityInitializeResponse } from './identityInitializeModel';

import { handleErrors } from '../../../../errorService';
import { getData, RequestAndUnwrap, requestFlow, unwrapService } from '../../../../utilities';

export const fetchIdentityInitialize = (verificationRequestToken: string, partnerId: string) =>
    requestFlow(handleErrors)(
        `/v0/natural-person/identity/initialize/${verificationRequestToken}/${partnerId}`
    );

const fetchAndMap = (verificationRequestToken: string, partnerId: string) =>
    fetchIdentityInitialize(
        verificationRequestToken,
        partnerId
    )<IdentityInitializeResponse>(getData);

export const getIdentityCredentials = (
    verificationRequestToken: string,
    partnerId: string
): RequestAndUnwrap<'get', IdentityInitializeResponse> =>
    flow(
        fetchAndMap(verificationRequestToken, partnerId),
        unwrapService<IdentityInitializeResponse>
    );
