import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import type { VerificationStatusPart } from '@dock/services';

import { useEmailContext } from '../../hooks/useEmailContext';
import { useHandleBackButton } from '../../hooks/useHandleBackButton';
import { useRedirectToNotFoundPage } from '../../hooks/useRedirectToNotFoundPage';
import {
    mapStatusToCurrentSubStep,
    SubStepsType,
} from '../../utils/functions/mapStatusToCurrentSubStep';
import { EmailConfirmation } from '../EmailConfirmation/EmailConfirmation';
import { EmailVerification } from '../EmailVerification/EmailVerification';

export type EmailStepTypes = {
    status: VerificationStatusPart | undefined;
};

export function EmailStep({ status }: EmailStepTypes) {
    const navigate = useNavigate();

    const { email } = useEmailContext();

    const [currentStep, setCurrentStep] = useState<SubStepsType>(
        mapStatusToCurrentSubStep(status, navigate)
    );

    useEffect(() => {
        if (!email) {
            setCurrentStep(0);
        }
    }, []);

    const renderProperStep = useCallback(() => {
        if (currentStep === 0) {
            return <EmailVerification setCurrentStep={setCurrentStep} />;
        }

        return <EmailConfirmation />;
    }, [currentStep]);

    useHandleBackButton(currentStep, setCurrentStep);

    useRedirectToNotFoundPage(currentStep === null, [currentStep]);

    return renderProperStep();
}
