import lang from '../../lang/home';
import { StepHeading } from '../../StepHeading';

export function HomePageHeading() {
    return (
        <StepHeading
            headerText={lang.HOME_HEADING}
            contentText={lang.HOME_CONTENT}
            contentTestId="homeText"
            headerTestId="homeHeader"
        />
    );
}
