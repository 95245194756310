import { flow } from 'fp-ts/lib/function';

import type { IdentityInfoResponse } from './identityInfoModel';

import { handleErrors } from '../../../../errorService';
import { getData, RequestAndUnwrap, requestFlow, unwrapService } from '../../../../utilities';

export const fetchIdentityInfo = (partnerId: string, productId: string) =>
    requestFlow(handleErrors)(`/v0/natural-person/identity/info/${partnerId}/${productId}`);

const fetchAndMap = (partnerId: string, productId: string) =>
    fetchIdentityInfo(partnerId, productId)<IdentityInfoResponse>(getData);

export const getIdentityInfo = (
    partnerId: string,
    productId: string
): RequestAndUnwrap<'get', IdentityInfoResponse> =>
    flow(fetchAndMap(partnerId, productId), unwrapService<IdentityInfoResponse>);
