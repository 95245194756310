import { Controller, Control } from 'react-hook-form';

import { Input } from '@dock/react';

type ControlledInputProps = {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<any, any>;
    isFullWidth?: boolean;
    label: string;
    disabled?: boolean;
    maxLength?: number;
    helperText?: string;
    defaultValue?: string;
};

// TODO: should be combined with ControlledInput in the lib/react level
export function ControlledInput({
    control,
    defaultValue = '',
    disabled,
    helperText,
    isFullWidth = false,
    label,
    maxLength,
    name,
}: ControlledInputProps) {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Input
                    {...rest}
                    inputProps={{ inputRef: ref }}
                    label={label}
                    errorMessage={error?.message}
                    isFullWidth={isFullWidth}
                    disabled={!!disabled}
                    maxLength={maxLength}
                    helperText={helperText}
                />
            )}
        />
    );
}
