import { Box, Button } from '@mui/material';

import { DockIdServiceError } from '../../../utils/DockIdServiceError';
import { ErrorAlertWrapper } from '../../ErrorAlertWrapper/ErrorAlertWrapper';
import lang from '../../lang/webIdVerification';
import { StepHeading } from '../../StepHeading';
import { wrapperStyles } from '../WebIdVerificationWithAutoRedirect';

type WebIdVerificationWithoutAutoRedirectProps = {
    redirectUrl: string;
    isRedirectButtonDisabled: boolean;
    error: DockIdServiceError | null;
    onRedirectToWebIdCallback: () => void;
};

export function WebIdVerificationWithoutAutoRedirect({
    error,
    isRedirectButtonDisabled,
    onRedirectToWebIdCallback,
    redirectUrl,
}: WebIdVerificationWithoutAutoRedirectProps) {
    return (
        <Box sx={wrapperStyles}>
            <StepHeading
                headerText={lang.HEADER_TEXT}
                contentText={lang.CONTENT_TEXT}
                headerTestId="webIdVerificationHeader"
                contentTestId="webIdVerificationContent"
            />

            <Button
                href={redirectUrl}
                target="_blank"
                onClick={onRedirectToWebIdCallback}
                disabled={isRedirectButtonDisabled}
                variant="contained"
                sx={{ mt: 4.25, p: '14px 80px' }}
                data-testid="webIdVerificationButton"
            >
                {lang.BUTTON_TEXT}
            </Button>
            <ErrorAlertWrapper error={error} />
        </Box>
    );
}
