import { OptionType } from '@dock/react-components';

import lang from '../../../components/lang/personalDetails';
import { Gender } from './enums';

export const genderOptions: OptionType<Gender>[] = [
    { label: lang.MALE, value: Gender.MALE },
    { label: lang.FEMALE, value: Gender.FEMALE },
    { label: lang.DIVERSE, value: Gender.DIVERSE },
];
