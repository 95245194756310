import { getActiveWebId } from '../../client';

export const fetchActiveWebId = async (identityId: string, identityVerificationId: string) => {
    const activeWebIdOrError = await getActiveWebId(identityId, identityVerificationId);

    if (activeWebIdOrError instanceof Error) {
        throw activeWebIdOrError;
    }
    return activeWebIdOrError;
};
