import type { NavigateFunction } from 'react-router-dom';

import { VerificationStatusPart } from '@dock/services';

import { NOT_FOUND_PAGE } from '../../constants';

export type SubStepsType = 0 | 1 | null;

export const mapStatusToCurrentSubStep = (
    status: VerificationStatusPart | undefined,
    navigate: NavigateFunction
): SubStepsType => {
    if (status === undefined) {
        navigate(NOT_FOUND_PAGE);
    }

    if (status === VerificationStatusPart.PENDING) {
        return 1;
    }

    if (status === VerificationStatusPart.NEW) {
        return 0;
    }

    return null;
};
