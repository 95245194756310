import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { camelizeKeys, decamelizeKeys, GenericServiceError } from '@dock/common';

export const interceptorResponse = (response: AxiosResponse) => {
    if (response.data && response.headers['content-type']?.includes('application/json')) {
        response.data = camelizeKeys(response.data);
    }

    return response;
};

export const interceptorError = (error: unknown) => {
    if (!axios.isAxiosError(error)) {
        return Promise.reject(error);
    }

    if (error.response) {
        // The request was made and the server responded with a status code
        return Promise.reject(
            new GenericServiceError(
                error.response.data.message,
                error.response.status,
                error.response.config.url,
                error.response.data.reason,
                undefined,
                error.response.config
            )
        );
    }

    if (error.request) {
        // The request was made but no response was received
        return Promise.reject(
            new GenericServiceError(
                error.message,
                undefined,
                error.config?.url,
                undefined,
                undefined,
                error.config
            )
        );
    }

    return Promise.reject(
        new GenericServiceError(
            `Something happened in setting up the request that triggered an Error: ${error.message}`
        )
    );
};

export const addHeaderContentType = (config: InternalAxiosRequestConfig) => {
    const newConfig = { ...config };
    if (newConfig.headers && newConfig.headers['Content-Type'] === 'multipart/form-data') {
        return newConfig;
    }
    return newConfig;
};

export const decamelizeParams = (config: InternalAxiosRequestConfig) => {
    const newConfig = { ...config };
    if (newConfig.params) {
        newConfig.params = decamelizeKeys(newConfig.params);
    }
    return newConfig;
};

export const decamelizeData = (config: InternalAxiosRequestConfig) => {
    const newConfig = { ...config };
    if (newConfig.data) {
        newConfig.data = decamelizeKeys(newConfig.data);
    }
    return newConfig;
};

export const decamelizeKeysInterceptorRequest = async (
    config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> =>
    addHeaderContentType(decamelizeParams(decamelizeData({ ...config })));
