import { getWebIdFields } from '../../client';

export const fetchWebIdFields = async (identityId: string, identityVerificationId: string) => {
    const webIdFieldsOrError = await getWebIdFields(identityId, identityVerificationId);

    if (webIdFieldsOrError instanceof Error) {
        throw webIdFieldsOrError;
    }
    return webIdFieldsOrError;
};
