import { captureException } from '@sentry/react';

import type { RequestAndUnwrap } from '@dock/services';

import { createHttpInstance, http, Request, GET, POST } from '@dock/http-request';

import { ENV_VARS } from '../../constants/envVars';
import { interceptorRequest } from './interceptors';

export const httpGenerator = (url: string, withCredentials = true) => {
    // we can add common interceptors for your package instance here.
    const instance = createHttpInstance(url);

    if (withCredentials) {
        instance.interceptors.request.use(interceptorRequest);

        instance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (ENV_VARS.IS_PRODUCTION_BUILD) {
                    captureException(error);
                }
            }
        );
    }

    const createdHttp = http(instance);

    return <V extends keyof Request>(verb: V) => {
        // you can ask for an specific verb: get/post/put
        const action = createdHttp[verb];

        return <R>(service: RequestAndUnwrap<V, R>) => service(action);
    };
};

// Dockid Consumer API instance
const dockIdHttpGenerator = httpGenerator(ENV_VARS.PLATFORM_CONSUMER_API);
export const apiGet = dockIdHttpGenerator(GET);
export const apiPost = dockIdHttpGenerator(POST);
