import React, { createContext, useContext } from 'react';

export type EmailContextType = {
    email: string | undefined;
    setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const EmailContext = createContext<EmailContextType>({
    email: undefined,
    setEmail: () => {},
});

export function EmailProvider({ children }: { children: React.ReactNode }) {
    const [email, setEmail] = React.useState<string | undefined>(undefined);

    const values = React.useMemo(() => ({ email, setEmail }), [email, setEmail]);

    return <EmailContext.Provider value={values}>{children}</EmailContext.Provider>;
}

export const useEmailContext = () => {
    const context = useContext(EmailContext);
    if (context === undefined) {
        throw new Error('useEmailContext must be used within an EmailProvider');
    }

    return context;
};
