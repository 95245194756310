import React, { createContext, useContext } from 'react';

export type PhoneNumberContextType = {
    phoneNumber: string;
    setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
};

export const PhoneNumberContext = createContext<PhoneNumberContextType>({
    phoneNumber: '',
    setPhoneNumber: () => {},
});

export function PhoneNumberProvider({ children }: { children: React.ReactNode }) {
    const [phoneNumber, setPhoneNumber] = React.useState('');

    const values = React.useMemo(
        () => ({ phoneNumber, setPhoneNumber }),
        [phoneNumber, setPhoneNumber]
    );

    return <PhoneNumberContext.Provider value={values}>{children}</PhoneNumberContext.Provider>;
}

export const usePhoneNumberContext = () => {
    const context = useContext(PhoneNumberContext);
    if (context === undefined) {
        throw new Error('usePhoneNumberContext must be used within an PhoneNumberProvider');
    }

    return context;
};
