import { createTheme, CssBaseline, ThemeOptions, ThemeProvider } from '@mui/material';
import React from 'react';

import { DEFAULT_THEME } from '@dock/react';

export function ThemeRoot({ children }: { children: React.ReactNode }) {
    // TODO: we do not need it for MVP, but in the future it is a mandatory feature
    const getCustomThemeConfig = React.useCallback(
        (): ThemeOptions => ({
            components: {
                MuiButton: {
                    styleOverrides: {
                        contained: ({ theme }) => ({
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                            },
                        }),
                        root: () => ({
                            fontSize: '16px',
                        }),
                    },
                },
            },
        }),
        []
    );

    const theme = createTheme(DEFAULT_THEME, getCustomThemeConfig());

    return (
        <>
            <CssBaseline />
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </>
    );
}
