import { GenericServiceError } from '@dock/common';
import { EmailVerificationCallbackBody } from '@dock/services';

import { postVerifyEmailCallback } from '../../client';

export const verifyEmailCallback = async (
    payload: EmailVerificationCallbackBody
): Promise<unknown> => {
    const errorOrResponse = await postVerifyEmailCallback({ payload });

    if (errorOrResponse instanceof GenericServiceError) {
        throw errorOrResponse;
    }

    return errorOrResponse;
};
