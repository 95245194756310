import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import type { VerificationStatusPart } from '@dock/services';

import { useHandleBackButton } from '../../hooks/useHandleBackButton';
import { usePhoneNumberContext } from '../../hooks/usePhoneNumberContext';
import { useRedirectToNotFoundPage } from '../../hooks/useRedirectToNotFoundPage';
import {
    mapStatusToCurrentSubStep,
    SubStepsType,
} from '../../utils/functions/mapStatusToCurrentSubStep';
import { PhoneNumberConfirmation } from '../PhoneNumberConfirmation/PhoneNumberConfirmation';
import { PhoneNumberVerification } from '../PhoneNumberVerification';

export type PhoneStepType = {
    status: VerificationStatusPart | undefined;
};

export function PhoneStep({ status }: PhoneStepType) {
    const navigate = useNavigate();

    const { phoneNumber } = usePhoneNumberContext();

    const [currentStep, setCurrentStep] = useState<SubStepsType>(
        mapStatusToCurrentSubStep(status, navigate)
    );

    useEffect(() => {
        if (!phoneNumber) {
            setCurrentStep(0);
        }
    }, []);

    const renderProperStep = useCallback(() => {
        if (currentStep === 0) {
            return <PhoneNumberVerification setCurrentStep={setCurrentStep} />;
        }

        return <PhoneNumberConfirmation />;
    }, [currentStep]);

    useHandleBackButton(currentStep, setCurrentStep);

    useRedirectToNotFoundPage(currentStep === null, [currentStep]);

    return renderProperStep();
}
