import lang from '../../components/lang/webIdStatus';
import { Status, StatusPage } from '../../components/StatusPage/StatusPage';

export function FailurePage() {
    return (
        <StatusPage
            additionalInfoContent={lang.additionalFailureInfoContent}
            headingContent={lang.headingFailureContent}
            status={Status.FAILURE}
        />
    );
}
