import { useQuery } from '@tanstack/react-query';

import type { IdentityInitializeResponse } from '@dock/services';

import { ACCESS_TOKEN, REFRESH_TOKEN } from '@dock/common';
import { useLocalStorage } from '@dock/react-hooks';

import { LocalStorageKeys } from '../../../constants';
import { DockIdServiceError } from '../../../utils/DockIdServiceError';
import { IDENTITY_INITIALIZE_KEY } from '../../keys';
import { fetchIdentityInitialize } from '../methods';

export function useIdentityInitialize(verificationRequestToken: string, partnerId: string) {
    const { setValue: setAccessToken } = useLocalStorage<string>(ACCESS_TOKEN);
    const { setValue: setRefreshToken } = useLocalStorage<string>(REFRESH_TOKEN);

    const { setValue: setIdentityId } = useLocalStorage<string>(LocalStorageKeys.IDENTITY_ID);

    const { data, error, isError, isLoading } = useQuery<
        IdentityInitializeResponse,
        DockIdServiceError,
        IdentityInitializeResponse
    >(
        [IDENTITY_INITIALIZE_KEY],
        () => fetchIdentityInitialize(verificationRequestToken, partnerId),
        {
            enabled: !!verificationRequestToken && !!partnerId,
            onSuccess: ({ accessToken, identityId, refreshToken }) => {
                if (identityId) {
                    setIdentityId(identityId);
                }

                if (accessToken) {
                    setAccessToken(accessToken);
                }

                if (refreshToken) {
                    setRefreshToken(refreshToken);
                }
            },
        }
    );

    return { data, error, isError, isLoading };
}
