import {
    getIdentityCredentials as getIdentityCredentialsService,
    getIdentityStatus as getIdentityStatusService,
    verifyEmail as verifyEmailService,
    verifyEmailCallback as verifyEmailCallbackService,
    verifyPhoneNumber as verifyPhoneNumberService,
    verifyWebId as verifyWebIdService,
    verifyPhoneNumberCallback as verifyPhoneNumberCallbackCallback,
    getVerifyActiveWebId as getVerifyActiveWebIdService,
    getWebIdFields as getWebIdFieldsService,
    getIdentityInfo as getIdentityService,
} from '@dock/services';

import { apiGet, apiPost } from './instance';

export const getIdentityCredentials = (verificationRequestToken: string, partnerId: string) =>
    apiGet(getIdentityCredentialsService(verificationRequestToken, partnerId))({});

export const getIdentityStatus = (identityId: string) =>
    apiGet(getIdentityStatusService(identityId))({});

export const getActiveWebId = (identityId: string, identityVerificationId: string) =>
    apiGet(getVerifyActiveWebIdService(identityId, identityVerificationId))({});

export const getWebIdFields = (identityId: string, identityVerificationId: string) =>
    apiGet(getWebIdFieldsService(identityId, identityVerificationId))({});

export const getIdentityInfo = (partnerId: string, productId: string) =>
    apiGet(getIdentityService(partnerId, productId))({});

export const postVerifyEmail = apiPost(verifyEmailService);

export const postVerifyEmailCallback = apiPost(verifyEmailCallbackService);

export const postVerifyPhoneNumber = apiPost(verifyPhoneNumberService);

export const postVerifyWebId = apiPost(verifyWebIdService);

export const postVerifyPhoneNumberCallback = apiPost(verifyPhoneNumberCallbackCallback);
