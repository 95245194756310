import { getIdentityInfo } from '../../client';

export const fetchIdentityInfo = async (partnerId: string, productId: string) => {
    const identityInfoOrError = await getIdentityInfo(partnerId, productId);

    if (identityInfoOrError instanceof Error) {
        throw identityInfoOrError;
    }
    return identityInfoOrError;
};
