import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

type PageWrapperTypes = {
    children: React.ReactNode;
    sx?: SxProps<Theme>;
};

export function PageWrapper({ children, sx }: PageWrapperTypes) {
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                maxWidth: '750px',
                mt: '100px',
                mx: 'auto',
                width: '100%',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
}
