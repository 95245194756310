import lang from '../../lang/emailVerification';
import { StepHeading } from '../../StepHeading';

export function EmailVerificationHeading() {
    return (
        <StepHeading
            headerText={lang.EMAIL_VERIFICATION}
            contentText={lang.PROCEED_VERIFICATION}
            headerTestId="emailVerificationHeading"
            contentTestId="emailVerificationContent"
        />
    );
}
