import { Box } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { Stepper } from '@dock/react-components';

import { LocalStorageKeys } from '../../constants';
import { useGlobalContext } from '../../hooks';
import { LOGO_MAX_HEIGHT, LOGO_MAX_WIDTH, Logo } from './Logo';
import { NavbarSkeleton } from './NavbarSkeleton';
import { NavbarWrapper } from './NavbarWrapper';

const HOME_ROUTE = '/';

const stepperStyles = {
    '& .MuiStepLabel-iconContainer .Mui-active': {
        color: 'primary.main',
    },
    '& .MuiStepLabel-iconContainer .Mui-completed': {
        color: 'primary.main',
    },
};

const MINIMAL_VALUE_WHEN_STEPPER_IS_SHOWN = 2;

export function Navbar() {
    const { activeSteps, currentStep, shouldShowNavbar } = useGlobalContext();

    const [logoUrl, setLogoUrl] = useState<string | null>(null);

    // We have to use native local storage because we have to wait for shouldShowNavbar to be true
    // and we can't use useLocalStorage hook because it will throw an error
    useEffect(() => {
        if (shouldShowNavbar) {
            const stringifiedLogoUrl = localStorage.getItem(LocalStorageKeys.LOGO_URL);

            const parsedLogoUrl =
                typeof stringifiedLogoUrl === 'string'
                    ? JSON.parse(stringifiedLogoUrl || '')
                    : null;

            setLogoUrl(parsedLogoUrl);
        }
    }, [shouldShowNavbar]);

    const { pathname } = useLocation();

    const isStepperNotAvailable =
        currentStep === null ||
        pathname === HOME_ROUTE ||
        activeSteps.length < MINIMAL_VALUE_WHEN_STEPPER_IS_SHOWN;

    const renderProperElement = useCallback(() => {
        if (!shouldShowNavbar) {
            return <NavbarSkeleton />;
        }

        return (
            <NavbarWrapper>
                {!!logoUrl && (
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            height: LOGO_MAX_HEIGHT,
                            justifyContent: 'center',
                            ml: 11,
                            mr: 10,
                            width: LOGO_MAX_WIDTH,
                        }}
                    >
                        <Logo logoUrl={logoUrl} />
                    </Box>
                )}

                {!isStepperNotAvailable ? (
                    <Stepper
                        activeStep={currentStep}
                        steps={activeSteps}
                        connector={null}
                        stepperStyles={stepperStyles}
                    />
                ) : null}
            </NavbarWrapper>
        );
    }, [shouldShowNavbar, activeSteps, pathname, currentStep, logoUrl]);

    return renderProperElement();
}
