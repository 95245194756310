export function isDomReady() {
    return document.readyState !== 'loading';
}

export function onDomReady(fn: () => void) {
    if (isDomReady()) {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}
