import { GenericServiceError } from '@dock/common';
import { MobilePhoneVerificationCallbackBody } from '@dock/services';

import { postVerifyPhoneNumberCallback } from '../../client';

export const verifyPhoneNumberCallback = async (
    payload: MobilePhoneVerificationCallbackBody
): Promise<unknown> => {
    const errorOrResponse = await postVerifyPhoneNumberCallback({ payload });

    if (errorOrResponse instanceof GenericServiceError) {
        throw errorOrResponse;
    }

    return errorOrResponse;
};
